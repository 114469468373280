import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import ClientsOverview from './Overview';
import ClientNewEntry from './New';
import ClientDetails from './Details';
import OverviewAppointments from './OverviewAppointments';
import OverviewResubmission from './OverviewResubmission';

const Clients = (props) => {
  const paths = {
    new: `${props.match.path}/new`,
    details: `${props.match.path}/detail/:clientGuid?/:log?`,
    search: `${props.match.path}/search/:search?`,
    overviewAppointments: `${props.match.path}/appointments`,
    overviewResubmissions: `${props.match.path}/resubmissions`,
    overview: `${props.match.path}/`,
  };

  return (
    <div>
      <Switch>
        <Route path={paths.new} component={ClientNewEntry} />
        <Route path={paths.details} component={ClientDetails} />
        <Route path={paths.search} component={ClientsOverview} />
        <Route
          path={paths.overviewAppointments}
          component={OverviewAppointments}
        />
        <Route
          path={paths.overviewResubmissions}
          component={OverviewResubmission}
        />
        <Route path={paths.overview} component={ClientsOverview} />
      </Switch>
    </div>
  );
};

export default withRouter(Clients);
