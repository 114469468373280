import {
  AddressAutofill,
  AddressMinimap,
  config,
  useAddressAutofillCore,
  useConfirmAddress,
} from '@mapbox/search-js-react';
import React, { useCallback, useEffect, useState } from 'react';
import settings from '../../settings';
import Form from './Form';
import Input from './Inputs/Input';

/**
 *
 * @function AddressMapAutofill
 * @description
 * * A component that allows users to autofill their address using the Mapbox API.
 * @param {Object} addressObject - The full address object containing the Address, Postcode, and City.
 * @param {Function} onChange - A function that is called when the address is changed.
 * @returns {React.Component} A React component.
 */

export default function AddressMapAutofill({ addressObject, onChange }) {
  const [showMinimap, setShowMinimap] = useState(false);
  const [fullAddress, setFullAddress] = useState(
    addressObject
      ? addressObject
      : {
          Address: '',
          Postcode: '',
          City: '',
        },
  );
  const [feature, setFeature] = useState();
  const [token, setToken] = useState('');

  const accessToken = settings.external.mapbox_key;
  const autofill = new useAddressAutofillCore({
    accessToken,
    language: 'de',
    country: 'DE',
  });

  const getAddressSuggestions = async () => {
    /**
     * @function getAddressSuggestions
     * @description
     * * Fetch data from the Mapbox suggestions API using the full address strings (Address, Postcode, City).
     * @todo
     * * If no suggestions are found, return.
     * * If suggestions are found, fetch the features using the first suggestion.
     * * Set the feature state and show the minimap.
     * ? If need help, refer to the Mapbox API Hooks documentation: https://docs.mapbox.com/mapbox-search-js/api/react/hooks/
     * ! Do not delete this description. It is used for documentation.
     */

    try {
      const result = await autofill.suggest(
        `${fullAddress.Address}, ${fullAddress.Postcode} ${fullAddress.City}`,
        {
          sessionToken: 'test-123',
        },
      );
      if (result.suggestions.length === 0) return;

      const suggestion = result.suggestions[0];
      const { features } = await autofill.retrieve(suggestion, {
        sessionToken: 'test-123',
      });
      const feature = features[0];
      setFeature(feature);
      setShowMinimap(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    setToken(accessToken);
    config.accessToken = accessToken;
    if (fullAddress.Address) getAddressSuggestions();
  }, [accessToken, fullAddress.Address]);

  const { formRef } = useConfirmAddress({
    minimap: true,
    skipConfirmModal: (feature) => {
      ['exact', 'high'].includes(feature.properties.match_code.confidence);
    },
  });

  const handleRetrieve = useCallback((res) => {
    const feature = res.features[0];
    setFeature(feature);
    setFullAddress({
      Address: feature.properties?.address_line1,
      Postcode: feature.properties?.postcode,
      City: feature.properties?.place,
    });
    setShowMinimap(true);
  }, []);

  function handleSaveMarkerLocation(coordinate) {
    console.log(`Marker moved to ${JSON.stringify(coordinate)}.`);
  }

  if (onChange) {
    onChange(fullAddress);
  }

  return (
    <Form ref={formRef} className="flex flex--column">
      <div className="AutofillCheckout">
        <div className="itemOne">
          {/* Input form */}
          <AddressAutofill
            options={{
              language: 'de',
              country: 'DE',
            }}
            accessToken={token}
            onRetrieve={handleRetrieve}
          >
            <Input
              label="Straße, Hausnummer:"
              id="mapbox-autofill"
              placeholder="Start typing your address, e.g. 123 Main..."
              autoComplete="address-line1"
              defaultValue={fullAddress.Address}
              onChange={(value) => {
                setFullAddress({
                  ...fullAddress,
                  address: value,
                });
              }}
              fullWidth
            />
          </AddressAutofill>
          <br />
          <Input
            label="PLZ:"
            id="mapbox-postcode"
            placeholder="ZIP / Postcode"
            autoComplete="postal-code"
            onChange={(value) => {
              setFullAddress({
                ...fullAddress,
                postcode: value,
              });
            }}
            defaultValue={fullAddress.Postcode}
            fullWidth
          />
          <br />
          <Input
            label="Ort:"
            id="mapbox-city"
            placeholder="City"
            autoComplete="address-level2"
            onChange={(value) => {
              setFullAddress({
                ...fullAddress,
                city: value,
              });
            }}
            defaultValue={fullAddress.City}
            fullWidth
          />
        </div>

        <div className="itemTwo">
          {/* Visual confirmation map */}
          <div
            id="minimap-container"
            className="h240 w360 relative mt18"
            style={{ height: 300 }}
          >
            <AddressMinimap
              canAdjustMarker={true}
              satelliteToggle={true}
              feature={feature}
              onSaveMarkerLocation={handleSaveMarkerLocation}
              show={showMinimap}
              footer={false}
              language="de"
            />
          </div>
        </div>
      </div>
    </Form>
  );
}
