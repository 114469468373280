import {
  faFilter,
  faMap,
  faRoute,
  faSearch,
} from '@fortawesome/pro-regular-svg-icons';
import mbxGeocoder from '@mapbox/mapbox-sdk/services/geocoding';
import moment from 'moment';
import React, { Component, createRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import ReactTable from 'react-table';
import reqwest from 'reqwest';
import settings from '../../../../../../settings';
import Button from '../../../../../components/Button';
import DisordersMainFocus from '../../../../../components/DisordersMainFocus';
import HeaderbarButton from '../../../../../components/HeaderbarButton';
import Loader from '../../../../../components/Loader';
import Map from '../../../../../components/Map';
import Searchbar from '../../../../../components/Searchbar';
import SearchDropdown from '../../../../../components/SearchDropdown';
import Treatments from '../../../../../components/Treatments';
import AmbPsychFilterContext from '../../../../../hooks/AmbPsychFilterContext';
import { checkStatus } from '../../../../../logic/checkStatus';
import getConstants from '../../../../../logic/constants';
import DataContainer, {
  deepParseJson,
} from '../../../../../logic/dataContainer';

class SelectAmbulatoryPsychotherapyOverview extends Component {
  static contextType = AmbPsychFilterContext;
  constructor(props) {
    super(props);
    this.city = createRef();
    this.searchLatLonValue = createRef();
    this.clientGuid = props.match.params.clientGuid;
    this.clientName = props.match.params.clientName;

    this.geocodingClient = mbxGeocoder({
      accessToken: settings.external.mapbox_key,
    });

    this.state = {
      loading: true,
      viewMap: false,
      isSearching: false,
      data: [],
      selectedAddress: '',
      searchLatLon: null,
      addresses: [],
      Gender: [],
      Ages: [],
      TherapyProcedures: [],
      JobTitles: [],
      CashRegisterApprovals: [],
      ReimbursementProcedures: [],
      Disorders: [],
      OfferVideoTherapy: [],
      AgilTraining: [],
      ApprovalForGroupPsychotherapy: [],
    };
  }

  componentDidMount() {
    const promise = reqwest({
      method: 'get',
      url: settings.supplynetwork.ambulatoryPsychotherapy.getList,
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then((res) => {
      const parsedData = deepParseJson(res).ambulatoryPsychotherapyList;
      this.setState({
        data: parsedData,
      });
    });

    const getClientData = reqwest({
      method: 'get',
      url: settings.clientmanagement.dataContainer.get,
      data: {
        guid: this.clientGuid,
        ...(this.logHistory === 'log' && {
          isExtern: true,
          action: 'Klient gelesen',
        }),
      },
    }).then((res) => {
      this.clientDataContainer = new DataContainer(res);
    });

    Promise.all([promise, getClientData]).then(() => {
      this.setState({
        loading: false,
      });
    });
    this.initConstants();
    console.log('----init data:', this.context.filterData);
  }

  componentDidUpdate(_, prevState) {
    if (prevState.searchLatLon !== this.state.searchLatLon) {
      this.setState({ data: this.addDistance(this.state.data) });
    }
    if (prevState.selectedAddress !== this.state.selectedAddress) {
      this._streetSearch(this.state.selectedAddress);
    }
  }

  addDistance(data) {
    return data.map((element) => {
      let distance = null;
      if (
        element &&
        element.ZipLat &&
        element.ZipLon &&
        this.state.searchLatLon &&
        this.state.searchLatLon[0] &&
        this.state.searchLatLon[1]
      ) {
        const mLat = parseFloat(element.ZipLat.replace(',', '.'));
        const mLon = parseFloat(element.ZipLon.replace(',', '.'));
        distance = this.calculateDistance(
          {
            lng: mLon,
            lat: mLat,
          },
          {
            lng: this.state.searchLatLon[0],
            lat: this.state.searchLatLon[1],
          },
        );
      }
      return { ...element, Distance: distance };
    });
  }

  calculateDistance(checkPoint, centerPoint) {
    const ky = 40000 / 360;
    const kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky;
    const dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
    const dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
    return Math.round(Math.sqrt(dx * dx + dy * dy));
  }

  getAges() {
    const ages = Array.from(Array(73).keys());
    return ages.map((v) => {
      return { value: v + 18, label: v + 18 };
    });
  }

  getBarrierFreeAccess() {
    return (
      {
        value: 'true',
        label: 'Ja',
      },
      {
        value: 'false',
        label: 'Nein',
      }
    );
  }

  getReimbursementProcedures() {
    return [
      {
        value: 'yes',
        label: 'Ja',
      },
      {
        value: 'no',
        label: 'Nein',
      },
    ];
  }

  getOptions() {
    return [
      {
        value: true,
        label: 'Ja',
      },
      {
        value: false,
        label: 'Nein',
      },
    ];
  }

  async initConstants() {
    this.setState({
      Gender: await getConstants('Gender'),
      TherapyProcedures: await getConstants('TherapyProcedures'),
      JobTitles: await getConstants('JobTitle'),
      CashRegisterApprovals: await getConstants('CashRegisterApproval'),
      OfferVideoTherapy: this.getOptions(),
      AgilTraining: this.getOptions(),
      ApprovalForGroupPsychotherapy: this.getOptions(),
      ReimbursementProcedures: this.getReimbursementProcedures(),
      BarrierFreeAccess: this.getBarrierFreeAccess(),
      Ages: this.getAges(),
    });
  }

  cashRegisterApprovalsOptions() {
    return [
      {
        value: true,
        label: 'Ja',
      },
      {
        value: false,
        label: 'Nein',
      },
    ];
  }

  _search = (value) => {
    this.context.saveFilterData('Volltextsuche', value.toLowerCase());
  };

  _streetSearch = (value) => {
    this.geocodingClient
      .forwardGeocode({
        query: value,
      })
      .send()
      .then((response) => {
        const match = response.body;
        if (!match.features.length) return;

        this.setState({
          searchLatLon: match.features[0].center,
        });
      });
  };

  _changeView = () => {
    this.setState({
      viewMap: !this.state.viewMap,
    });
  };

  _toggleFilter = () => {
    this.context.toggleFilters();
  };

  _changeFilter = (filterName, filterValue) => {
    this.context.saveFilterData(filterName, filterValue);
  };

  /** Returns true if date is set and is today or still in future */
  isMarkedAvailable = (date) => {
    return date && moment(date, 'DD.MM.YYYY').isSameOrAfter(moment(), 'day');
  };

  /** Returns true if both dates are set and are both today or still in future */
  isFullyBlocked = (date1, date2) => {
    return (
      date1 &&
      date2 &&
      moment(date1, 'DD.MM.YYYY').isSameOrAfter(moment(), 'day') &&
      moment(date2, 'DD.MM.YYYY').isSameOrAfter(moment(), 'day')
    );
  };

  resetFilters = () => {
    this.context.clearFilterData();
    this.initConstants();
  };

  streetAndLatLonSearch = async () => {
    try {
      this.setState({
        isSearching: true,
      });
      await this.geocodingClient
        .forwardGeocode({
          query: this.city.current.value
            ? this.city.current.value
            : this.context.filterData.city,
        })
        .send()
        .then((response) => {
          const match = response.body;
          if (!match.features.length) return;
          this.setState({
            searchLatLon: match.features[0].center,
          });
        });

      this.context.saveFilterData(
        'Distance',
        this.searchLatLonValue.current.value,
      );
      this.context.saveFilterData('city', this.city.current.value);
      this.context.saveFilterData(
        'searchLatLonValue',
        this.searchLatLonValue.current.value,
      );
      this.context.setData(
        'selectAmbulatoryPsychotherapyOverviewData',
        this.addDistance(this.state.data),
      );
      this.setState({
        data: this.addDistance(this.state.data),
        isSearching: false,
      });
      this.context.saveSorting({ id: 'Distance', desc: false });
    } catch (error) {
      this.setState({
        isSearching: false,
      });
      swal({
        title: 'Nicht möglich.',
        icon: 'Straßen- und LatLon-Suche',
        text: error,
      });
    }
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    let data = [];
    if (this.state.data.length) {
      const filteredData = JSON.parse(localStorage.getItem('filterData'));

      if (
        filteredData?.city?.length > 0 &&
        filteredData?.searchLatLonValue?.length > 0 &&
        this.context.selectAmbulatoryPsychotherapyOverviewData.length > 0
      ) {
        data = this.context.filterAndSortData(
          this.context.filterRows(
            this.context.selectAmbulatoryPsychotherapyOverviewData,
          ),
        );
      } else {
        data = this.context.filterAndSortData(
          this.context.filterRows(this.state.data),
        );
      }
    }

    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            <Link to="/clientmanagement">Klientenverwaltung</Link>
            {' - '}
            <Link to={`/clientmanagement/detail/${this.clientGuid}`}>
              Klient &quot;{this.clientName}&quot;
            </Link>
            {' - '}
            <b>Therapeut/in auswählen oder suchen</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton
              icon={faMap}
              type="primary"
              onClick={this._changeView}
            >
              {this.state.viewMap ? 'Karte verbergen' : ' Karte anzeigen'}
            </HeaderbarButton>
          </div>
        </div>
        <div className="Split-Panes-Menu">
          <div className="Split-Pane-Menu">
            <div className="Split-Pane-Menu-Nav">
              <NavLink
                to={`/clientmanagement/detail/selectCounselling/SelectAmbulatoryPsychotherapyOverview/${this.clientGuid}/${this.clientName}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Ambulante Psychotherapie
              </NavLink>
            </div>
          </div>
          <div className="Split-Pane-Main">
            <div className="Page-Content">
              <div className="Buttonbar"></div>
              <div className="Toolbar">
                <Searchbar
                  placeholder="Volltextsuche"
                  defaultValue={this.context.filterData.Volltextsuche}
                  onChange={this._search}
                />
                <Button icon={faFilter} onClick={this._toggleFilter}>
                  {this.context.showFilters
                    ? 'Filter ausblenden '
                    : 'Filter einblenden'}
                </Button>
                <span className="Searchbar-Results-Amount">
                  {data.length} Einträge
                </span>
                <div className="Toolbar-buttons"></div>
              </div>
              <fieldset className="fieldset">
                <span>
                  <legend>Adressen des Klienten:</legend>
                </span>
                <div className="fieldset-radio">
                  <input
                    type="radio"
                    id="AddrStreetAndNumber"
                    name="Adress"
                    defaultValue={
                      this.clientDataContainer.get(
                        'Entry',
                        'AddrStreetAndNumber',
                      ) +
                      ', ' +
                      this.clientDataContainer.get('Entry', 'AddrCity')
                    }
                    onClick={(e) => {
                      this.setState({
                        selectedAddress: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="AddrStreetAndNumber">
                    Privat:{' '}
                    {this.clientDataContainer.get(
                      'Entry',
                      'AddrStreetAndNumber',
                    ) +
                      ', ' +
                      this.clientDataContainer.get('Entry', 'AddrCity')}
                  </label>
                </div>
                <div className="fieldset-radio">
                  <input
                    type="radio"
                    id="DeptStreetAndNumber"
                    name="Adress"
                    defaultValue={
                      this.clientDataContainer.get(
                        'Entry',
                        'DeptStreetAndNumber',
                      ) +
                      ', ' +
                      this.clientDataContainer.get('Entry', 'DutyStation')
                    }
                    onClick={(e) => {
                      this.setState({
                        selectedAddress: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="DeptStreetAndNumber">
                    Dienststelle:{' '}
                    {this.clientDataContainer.get(
                      'Entry',
                      'DeptStreetAndNumber',
                    ) +
                      ', ' +
                      this.clientDataContainer.get('Entry', 'DutyStation')}
                  </label>
                </div>
              </fieldset>
              <div className="Toolbar">
                <Searchbar
                  onRef={this.city}
                  key="city"
                  placeholder="Startadresse oder PLZ eingeben"
                  defaultValue={this.context.filterData.city}
                  value={this.context.filterData.city}
                  onChange={(value) => {
                    this._changeFilter('city', value);
                  }}
                />
                <Searchbar
                  onRef={this.searchLatLonValue}
                  key="searchLatLonValue"
                  placeholder="Umkreis in km"
                  icon={faRoute}
                  value={this.context.filterData.searchLatLonValue}
                  onChange={(value) => {
                    this._changeFilter('searchLatLonValue', value);
                  }}
                />
                <Button
                  type={'primary'}
                  icon={faSearch}
                  onClick={this.streetAndLatLonSearch}
                >
                  Suchen
                </Button>
                {this.context.showFilters && (
                  <Button
                    type={'primary'}
                    icon={faFilter}
                    onClick={this.resetFilters}
                  >
                    Filter zurücksetzen
                  </Button>
                )}
                <div className="Toolbar-buttons"></div>
              </div>
              <div className="Toolbar">
                <div className="Toolbar-buttons"></div>
              </div>
              {this.context.showFilters && (
                <>
                  <div className="Toolbar-Filter">
                    <SearchDropdown
                      label="Geschlecht:"
                      options={this.state.Gender}
                      defaultValue={this.context.filterData.Gender}
                      onChange={(value) => {
                        this._changeFilter('Gender', value);
                      }}
                    />
                    <SearchDropdown
                      label="Alter (+/- 5):"
                      options={this.state.Ages}
                      onChange={(value) => {
                        this._changeFilter('BirthYear', value);
                      }}
                    />
                    <SearchDropdown
                      label="Berufsbezeichnung:"
                      options={this.state.JobTitles}
                      defaultValue={this.context.filterData.JobTitle}
                      onChange={(value) => {
                        this._changeFilter('JobTitle', value);
                      }}
                    />
                    <SearchDropdown
                      label="Therapieverfahren:"
                      options={this.state.TherapyProcedures}
                      defaultValue={this.context.filterData.TherapyProcedures}
                      onChange={(value) => {
                        this._changeFilter('TherapyProcedures', value);
                      }}
                    />
                    <SearchDropdown
                      label="Zulassung zur Gruppentherapie:"
                      options={this.state.ApprovalForGroupPsychotherapy}
                      defaultValue={
                        this.context.filterData.ApprovalForGroupPsychotherapy
                      }
                      onChange={(value) => {
                        this._changeFilter(
                          'ApprovalForGroupPsychotherapy',
                          value,
                        );
                      }}
                    />
                    <SearchDropdown
                      label="AGIL:"
                      options={this.state.AgilTraining}
                      defaultValue={this.context.filterData.AgilTraining}
                      onChange={(value) => {
                        this._changeFilter('AgilTraining', value);
                      }}
                    />
                  </div>
                  <div className="Toolbar-Filter">
                    <Treatments
                      name="Treatments"
                      label="Behandlungsschwerpunkte in der praktischen Arbeit:"
                      info="Mehrfachauswahl ist möglich."
                      values={this.context.filterData.Treatments}
                      onChange={(values) => {
                        this._changeFilter('Treatments', values);
                      }}
                    />

                    <DisordersMainFocus
                      name="Disorders"
                      label="Störungsbilder Schwerpunkte:"
                      mainFocus={this.context.filterData.DisordersMainFocus}
                      onChange={(values) => {
                        this._changeFilter(
                          'DisordersMainFocus',
                          values.mainFocus,
                        );
                      }}
                    />
                    <SearchDropdown
                      label="Kassenzulassung:"
                      // options={this.state.CashRegisterApprovals}
                      options={this.cashRegisterApprovalsOptions()}
                      defaultValue={
                        this.context.filterData.CashRegisterApproval
                      }
                      onChange={(value) => {
                        this._changeFilter('CashRegisterApproval', value);
                      }}
                    />
                    <SearchDropdown
                      label="Kostenerstattungsverfahren:"
                      options={this.state.ReimbursementProcedures}
                      defaultValue={
                        this.context.filterData.ReimbursementProcedure
                      }
                      onChange={(value) => {
                        this._changeFilter('ReimbursementProcedure', value);
                      }}
                    />
                    <SearchDropdown
                      label="Videotherapie:"
                      options={this.state.OfferVideoTherapy}
                      defaultValue={this.context.filterData.OfferVideoTherapy}
                      onChange={(value) => {
                        this._changeFilter('OfferVideoTherapy', value);
                      }}
                    />
                  </div>
                </>
              )}

              {this.state.viewMap && (
                <>
                  <Map
                    markers={data}
                    streetPoint={this.state.searchLatLon}
                    radius={this.context.filterData.Distance}
                  />
                  <br />
                </>
              )}
              {this.state.isSearching ? (
                <Loader />
              ) : (
                <ReactTable
                  getTrProps={(_, val) => {
                    return {
                      className: checkStatus(val?.original),
                      onClick: () => {
                        return this.props.history.push(
                          `/clientmanagement/detail/selectCounselling/SelectAmbulatoryPsychotherapyDetail/${val?.original?.Guid}/${this.clientGuid}/${val?.original?.Lastname}/${this.clientName}`,
                        );
                      },
                    };
                  }}
                  defaultSorted={[this.context.sorting]}
                  onSortedChange={(sorted) => {
                    this.context.saveSorting(sorted[0]);
                  }}
                  data={this.state.isSearching ? <Loader /> : data}
                  pageSize={data.length}
                  noDataText="Es wurden keine Adressen gefunden."
                  columns={[
                    {
                      accessor: 'Lastname',
                      Header: 'Name',
                      Cell: ({ original }) => {
                        return <>{original.Lastname}</>;
                      },
                    },
                    {
                      accessor: 'firstname',
                      Header: 'Vorname',
                      Cell: ({ original }) => {
                        return <>{original.Firstname}</>;
                      },
                    },
                    {
                      accessor: 'street',
                      Header: 'Straße',
                      Cell: ({ original }) => {
                        return <>{original.Street}</>;
                      },
                    },
                    {
                      accessor: 'PLZ',
                      Header: 'PLZ',
                      Cell: ({ original }) => {
                        return <>{original.ZIP}</>;
                      },
                    },
                    {
                      accessor: 'place',
                      Header: 'Ort',
                      Cell: ({ original }) => {
                        return <>{original.City}</>;
                      },
                    },
                    {
                      accessor: 'EMail',
                      Header: 'E-Mail Adresse',
                      Cell: ({ original }) => {
                        return <>{original.MailToCare}</>;
                      },
                    },
                    {
                      accessor: 'Distance',
                      Header: this.state.searchLatLon ? 'Entfernung' : '',
                      Cell: ({ original }) => {
                        return (
                          <>
                            {original.Distance ? original.Distance + ' km' : ''}
                          </>
                        );
                      },
                    },
                    {
                      accessor: 'points',
                      Header: this.context.showFilters ? 'Filter' : '',
                      Cell: ({ original }) => {
                        return (
                          <div className="tooltip-filter">
                            {this.context.showFilters ? (
                              <>
                                {original.points
                                  ? original.points + ' %'
                                  : ' 0 %'}
                              </>
                            ) : (
                              ''
                            )}
                            <div className="tooltip-text-filter">
                              {original.matchedKeys?.length > 0 ? (
                                <>
                                  <h3>Zugetroffen Filter:</h3>
                                  <ul>
                                    {original.matchedKeys.map((filter, idx) => {
                                      return (
                                        <li key={idx} className="filter-items">
                                          <p className="check-filter">
                                            &#x2714;
                                          </p>
                                          {filter}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                  <h3>Nicht zugetroffen Filter:</h3>
                                  <ul>
                                    {original.unmatchedKeys.map(
                                      (filter, idx) => {
                                        if (filter !== '')
                                          return (
                                            <li
                                              key={idx}
                                              className="filter-items"
                                            >
                                              <p className="ex-filter ">
                                                &#215;
                                              </p>
                                              {filter}
                                            </li>
                                          );
                                      },
                                    )}
                                  </ul>
                                </>
                              ) : (
                                <h3>Keine Treffer</h3>
                              )}
                              <i></i>
                            </div>
                          </div>
                        );
                      },
                    },
                  ]}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SelectAmbulatoryPsychotherapyOverview;
