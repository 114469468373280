import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import reqwest from 'reqwest';
import swal from '@sweetalert/with-react';
import { faSave, faTimes } from '@fortawesome/pro-regular-svg-icons';
import settings from '../../../../settings';
import DataContainer from '../../../logic/dataContainer';
import Loader from '../../../components/Loader';
import Form from '../../../components/Form';
import ContentBox from '../../../components/FormArea/ContentBox';
import Dropdown from '../../../components/Inputs/Dropdown';
import Switch from '../../../components/Inputs/Switch';
import Button from '../../../components/Button';
import Input from '../../../components/Inputs/Input';
import HeaderbarButton from '../../../components/HeaderbarButton';

class SupplynetworkClinicsDetail extends Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp('/new'), '');

    this.state = {
      loading: false,
      positions: [],
    };

    this.dataContainer = new DataContainer();
    this.dataContainer.add('ClinicData', {
      ClinicName: '',
      Street: '',
      ZIP: '',
      City: '',
      Lat: '',
      Lon: '',
      ZipLat: '',
      ZipLon: '',
      Landline: '',
      Handy: '',
      Fax: '',
      Website: '',
      MailToCare: '',
      MailToPatient: '',
      MedicalAdministration: '',
      TypeOfClinic: '',
      TreatmentFocus: '',
      SpecialTreatmentConcepts: '',
      SpecialFeaturesOfTheClinic: '',
      CooperationAgreement: '',
      Unlocked: false,
    });

    this.dataContainer.add('PrivateInsured', {
      ClinicEligble: false,
      SingleBedRoom: false,
      ChiefPhysicianTreatment: false,
      OtherOptionalServices: '',
      AverageWaitingTimesAtTheClinic: '',
      ShorterWaitingTimesDueToCare: '',
      PrivateAverageLengthOfStayReha: '',
      PrivateAverageLengthOfStayAcute: '',
    });

    this.dataContainer.add('LegallyInsured', {
      LegallyInsuredPatientsTreated: false,
      LegallyAverageLengthOfStayReha: '',
      LegallyAverageLengthOfStayAcute: '',
    });

    this.dataContainer.add('Procedure', {
      ProcedureApplication: '',
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Fehler bei Ihren Eingaben',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.',
      });
      return;
    }

    const data = this.dataContainer.getStringified();

    reqwest({
      method: 'POST',
      url: settings.supplynetwork.clinics.save,
      data: {
        Token: localStorage.getItem('token'),
        ClinicsAdress: data,
      },
    }).then(() => {
      swal({
        title: 'Gespeichert!',
        text: 'Die Adresse wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            Versorgungsnetzwerk -{' '}
            <Link to="/supplynetwork/Clinics">Klinken</Link> - <b>Neu</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton icon={faTimes} to={this.parentRoute}>
              Abbrechen
            </HeaderbarButton>
          </div>
        </div>
        <div className="Page-Content">
          <div className="Buttonbar">
            <Button type="primary" icon={faSave} onClick={this.save}>
              Speichern
            </Button>
          </div>
          <Form
            ref={(node) => {
              return (this.form = node);
            }}
          >
            <ContentBox title="Angaben zur Klinik">
              <Input
                type="text"
                validator={[
                  {
                    required: true,
                    error: 'Pflichtfeld ist nicht ausgefüllt.',
                  },
                ]}
                onChange={(value) => {
                  this.dataContainer.set('ClinicData', 'ClinicName')(value);
                }}
                defaultValue={this.dataContainer.get(
                  'ClinicData',
                  'ClinicName',
                )}
                label="Name der Klinik:"
                name="ClinicName"
              />
              <Input
                type="text"
                validator={[
                  {
                    required: true,
                    error: 'Pflichtfeld ist nicht ausgefüllt.',
                  },
                ]}
                onChange={(value) => {
                  this.dataContainer.set('ClinicData', 'Street')(value);
                }}
                defaultValue={this.dataContainer.get('ClinicData', 'Street')}
                label="Straße:"
                name="Street"
              />
              <Input
                type="text"
                onChange={(value) => {
                  this.dataContainer.set('ClinicData', 'ZIP')(value);
                }}
                defaultValue={this.dataContainer.get('ClinicData', 'ZIP')}
                label="PLZ:"
                name="ZIP"
                validator={[
                  {
                    custom: async (value) => {
                      if (value === '') return true;
                      return await reqwest({
                        method: 'GET',
                        url: settings.supplynetwork.getCoordsToZip,
                        data: {
                          zip: value,
                        },
                      }).then((result) => {
                        if (result === 'No Data') return false;
                        result = [JSON.parse(result)];

                        const coords = new DataContainer(result);
                        if (coords) {
                          this.dataContainer.set(
                            'ClinicData',
                            'ZipLat',
                          )(coords.get('Coordinates', 'ZipLat'));
                          this.dataContainer.set(
                            'ClinicData',
                            'ZipLon',
                          )(coords.get('Coordinates', 'ZipLon'));
                          return true;
                        }
                      });
                    },
                    error: 'Diese PLZ existiert nicht.',
                  },
                ]}
              />
              <Input
                type="text"
                validator={[
                  {
                    required: true,
                    error: 'Pflichtfeld ist nicht ausgefüllt.',
                  },
                ]}
                onChange={(value) => {
                  this.dataContainer.set('ClinicData', 'City')(value);
                }}
                defaultValue={this.dataContainer.get('ClinicData', 'City')}
                label="Ort:"
                name="City"
              />
              <Input
                type="text"
                onChange={(value) => {
                  this.dataContainer.set('ClinicData', 'Landline')(value);
                }}
                defaultValue={this.dataContainer.get('ClinicData', 'Landline')}
                label="Telefon Festnetz:"
                name="Landline"
              />
              <Input
                type="text"
                onChange={(value) => {
                  this.dataContainer.set('ClinicData', 'Handy')(value);
                }}
                defaultValue={this.dataContainer.get('ClinicData', 'Handy')}
                label="Mobil:"
                name="Handy"
              />
              <Input
                type="text"
                onChange={(value) => {
                  this.dataContainer.set('ClinicData', 'Fax')(value);
                }}
                defaultValue={this.dataContainer.get('ClinicData', 'Fax')}
                label="Fax:"
                name="Fax"
              />
              <Input
                type="text"
                onChange={(value) => {
                  this.dataContainer.set('ClinicData', 'Website')(value);
                }}
                defaultValue={this.dataContainer.get('ClinicData', 'Website')}
                label="Internetauftritt:"
                name="Website"
                linkTo="Klicke, um die Internetadresse in einem neuen Fenster zu öffnen."
              />
              <Input
                type="text"
                onChange={(value) => {
                  this.dataContainer.set('ClinicData', 'MailToCare')(value);
                }}
                defaultValue={this.dataContainer.get(
                  'ClinicData',
                  'MailToCare',
                )}
                label="E-Mail Adresse für den Kontakt zu CARE:"
                name="MailToCare"
              />
              <Input
                type="text"
                onChange={(value) => {
                  this.dataContainer.set('ClinicData', 'MailToPatient')(value);
                }}
                defaultValue={this.dataContainer.get(
                  'ClinicData',
                  'MailToPatient',
                )}
                label="E-Mail Adresse für Patientenkontakt:"
                name="MailToPatient"
              />
              <Input
                type="text"
                onChange={(value) => {
                  this.dataContainer.set(
                    'ClinicData',
                    'MedicalAdministration',
                  )(value);
                }}
                defaultValue={this.dataContainer.get(
                  'ClinicData',
                  'MedicalAdministration',
                )}
                label="Ärztliche Leitung:"
                name="MedicalAdministration"
              />
              <Dropdown
                label="Form des Klinikbetriebs:"
                getSelected={(value) => {
                  this.dataContainer.set('ClinicData', 'TypeOfClinic')(value);
                }}
                defaultValue={this.dataContainer.get(
                  'ClinicData',
                  'TypeOfClinic',
                )}
                options={[
                  {
                    value: 'acute',
                    label: 'Akut',
                  },
                  {
                    value: 'reha',
                    label: 'Reha',
                  },
                  {
                    value: 'mixed',
                    label: 'Akut & Reha',
                  },
                ]}
                validator={[]}
                name="TypeOfClinic"
              />
              <Input
                type="textarea"
                onChange={(value) => {
                  this.dataContainer.set('ClinicData', 'TreatmentFocus')(value);
                }}
                validator={[]}
                defaultValue={this.dataContainer.get(
                  'ClinicData',
                  'TreatmentFocus',
                )}
                label="Behandlungsschwerpunkte der Stationen (ggf. Kontraindikationen):"
                name="TreatmentFocus"
              />
              <Input
                type="textarea"
                onChange={(value) => {
                  this.dataContainer.set(
                    'ClinicData',
                    'SpecialTreatmentConcepts',
                  )(value);
                }}
                validator={[]}
                defaultValue={this.dataContainer.get(
                  'ClinicData',
                  'SpecialTreatmentConcepts',
                )}
                label="Besondere Behandlungskonzepte (z.B. berufsspezifische Konzepte):"
                name="SpecialTreatmentConcepts"
              />
              <Input
                type="textarea"
                onChange={(value) => {
                  this.dataContainer.set(
                    'ClinicData',
                    'SpecialFeaturesOfTheClinic',
                  )(value);
                }}
                validator={[]}
                defaultValue={this.dataContainer.get(
                  'ClinicData',
                  'SpecialFeaturesOfTheClinic',
                )}
                label="Weitere Besonderheiten der Klinik (z.B. Ausschluss bestimmter Erkrankungen)"
                name="SpecialFeaturesOfTheClinic"
              />
              <Dropdown
                label="Kooperationsvereinbarung:"
                getSelected={(value) => {
                  this.dataContainer.set(
                    'ClinicData',
                    'CooperationAgreement',
                  )(value);
                }}
                validator={[]}
                defaultValue={this.dataContainer.get(
                  'ClinicData',
                  'CooperationAgreement',
                )}
                options={[
                  {
                    value: 'yes',
                    label: 'Ja',
                  },
                  {
                    value: 'no',
                    label: 'Nein',
                  },
                ]}
                name="CooperationAgreement"
              />
            </ContentBox>
            <ContentBox title="Angaben für privat Versicherte">
              <Switch
                label="Ist die Klinik beihilfefähig?:"
                labelActive="Ja"
                labelInactive="Nein"
                onChange={(value) => {
                  this.dataContainer.set(
                    'PrivateInsured',
                    'ClinicEligble',
                  )(value);
                }}
                name="ClinicEligble"
                defaultChecked={
                  !!this.dataContainer.get('PrivateInsured', 'ClinicEligble')
                }
                validator={[]}
              />
              <Switch
                label="Einzelbettzimmer:"
                labelActive="Ja"
                labelInactive="Nein"
                onChange={(value) => {
                  this.dataContainer.set(
                    'PrivateInsured',
                    'SingleBedRoom',
                  )(value);
                }}
                name="SingleBedRoom"
                defaultChecked={
                  !!this.dataContainer.get('PrivateInsured', 'SingleBedRoom')
                }
                validator={[]}
              />
              <Switch
                label="Cheftarztbehandlung:"
                labelActive="Ja"
                labelInactive="Nein"
                onChange={(value) => {
                  this.dataContainer.set(
                    'PrivateInsured',
                    'ChiefPhysicianTreatment',
                  )(value);
                }}
                name="ChiefPhysicianTreatment"
                defaultChecked={
                  !!this.dataContainer.get(
                    'PrivateInsured',
                    'ChiefPhysicianTreatment',
                  )
                }
                validator={[]}
              />
              <Input
                type="text"
                label="Weitere Wahlleistungen:"
                onChange={(value) => {
                  this.dataContainer.set(
                    'PrivateInsured',
                    'OtherOptionalServices',
                  )(value);
                }}
                validator={[]}
                defaultValue={this.dataContainer.get(
                  'PrivateInsured',
                  'OtherOptionalServices',
                )}
                name="OtherOptionalServices"
              />
              <Input
                type="text"
                label="Durchschnittliche Wartezeiten auf Klinikplatz:"
                onChange={(value) => {
                  this.dataContainer.set(
                    'PrivateInsured',
                    'AverageWaitingTimesAtTheClinic',
                  )(value);
                }}
                validator={[]}
                defaultValue={this.dataContainer.get(
                  'PrivateInsured',
                  'AverageWaitingTimesAtTheClinic',
                )}
                name="AverageWaitingTimesAtTheClinic"
              />
              <Input
                type="text"
                label="Gibt es evtl. verkürzte Wartezeiten durch die Kooperation mit CARE?:"
                onChange={(value) => {
                  this.dataContainer.set(
                    'PrivateInsured',
                    'ShorterWaitingTimesDueToCare',
                  )(value);
                }}
                validator={[]}
                defaultValue={this.dataContainer.get(
                  'PrivateInsured',
                  'ShorterWaitingTimesDueToCare',
                )}
                name="ShorterWaitingTimesDueToCare"
              />
              <Input
                type="text"
                label="Durchschnittliche Aufenthaltsdauer bei Reha:"
                onChange={(value) => {
                  this.dataContainer.set(
                    'PrivateInsured',
                    'PrivateAverageLengthOfStayReha',
                  )(value);
                }}
                validator={[]}
                defaultValue={this.dataContainer.get(
                  'PrivateInsured',
                  'PrivateAverageLengthOfStayReha',
                )}
                name="PrivateAverageLengthOfStayReha"
              />
              <Input
                type="text"
                label="Durchschnittliche Aufenthaltsdauer bei Akutbehandlung:"
                onChange={(value) => {
                  this.dataContainer.set(
                    'PrivateInsured',
                    'PrivateAverageLengthOfStayAcute',
                  )(value);
                }}
                validator={[]}
                defaultValue={this.dataContainer.get(
                  'PrivateInsured',
                  'PrivateAverageLengthOfStayAcute',
                )}
                name="PrivateAverageLengthOfStayAcute"
              />
            </ContentBox>
            <ContentBox title="Angaben für gesetzlich Versicherte">
              <Switch
                label="Werden gesetzlich versicherte Patienten in der Klinik behandelt?"
                labelActive="Ja"
                labelInactive="Nein"
                onChange={(value) => {
                  this.dataContainer.set(
                    'LegallyInsured',
                    'LegallyInsuredPatientsTreated',
                  )(value);
                }}
                name="LegallyInsuredPatientsTreated"
                defaultChecked={
                  !!this.dataContainer.get(
                    'LegallyInsured',
                    'LegallyInsuredPatientsTreated',
                  )
                }
                validator={[]}
              />
              <Input
                type="text"
                label="Durchschnittliche Aufenthaltsdauer bei Reha:"
                onChange={(value) => {
                  this.dataContainer.set(
                    'LegallyInsured',
                    'LegallyAverageLengthOfStayReha',
                  )(value);
                }}
                validator={[]}
                defaultValue={this.dataContainer.get(
                  'LegallyInsured',
                  'LegallyAverageLengthOfStayReha',
                )}
                name="LegallyAverageLengthOfStayReha"
              />
              <Input
                type="text"
                label="Durchschnittliche Aufenthaltsdauer bei Akutbehandlung:"
                onChange={(value) => {
                  this.dataContainer.set(
                    'LegallyInsured',
                    'LegallyAverageLengthOfStayAcute',
                  )(value);
                }}
                validator={[]}
                defaultValue={this.dataContainer.get(
                  'LegallyInsured',
                  'LegallyAverageLengthOfStayAcute',
                )}
                name="LegallyAverageLengthOfStayAcute"
              />
            </ContentBox>
            <ContentBox title="Verfahren">
              <Input
                label="Welche Formulare/ Unterlagen werden zur Antragsstellung benötigt?"
                type="textarea"
                onChange={(value) => {
                  this.dataContainer.set(
                    'Procedure',
                    'ProcedureApplication',
                  )(value);
                }}
                name="ProcedureApplication"
                defaultChecked={
                  !!this.dataContainer.get('Procedure', 'ProcedureApplication')
                }
                validator={[]}
              />
            </ContentBox>
          </Form>
        </div>
      </>
    );
  }
}

export default SupplynetworkClinicsDetail;
