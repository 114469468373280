import {
  faArrowLeft,
  faSave,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import swal from '@sweetalert/with-react';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import reqwest from 'reqwest';
import settings from '../../../../../../settings';
import Button from '../../../../../components/Button';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import HeaderbarButton from '../../../../../components/HeaderbarButton';
import Dropdown from '../../../../../components/Inputs/Dropdown';
import Loader from '../../../../../components/Loader';
import getConstants from '../../../../../logic/constants';
import DataContainer from '../../../../../logic/dataContainer';

class CaseStatisticsNewEntry extends Component {
  constructor(props) {
    super(props);
    this.clientGuid = props.match.params.clientGuid;
    this.parentRoute = this.props.match.url.replace(
      new RegExp('/caseStatistics'),
      ``,
    );

    this.state = {
      loading: false,
      PreviousUseOfCareOption: [],
      RecommendedInterventionOption: [],
      ArrangementOfPsychotherapyThroughCare: [],
      CarePsychologistLocation: [],
      RegionalAssignmentOfTheCase: [],
      NoAssistanceFromCareOption: [],
      PlaceOfConsultation: [],
      CarePsych: [],
      selectedOption: {
        PreviousUseOfCareOption: [],
        RecommendedInterventionOption: [],
        ArrangementOfPsychotherapyThroughCare: [],
        CarePsychologistLocation: [],
        RegionalAssignmentOfTheCase: [],
        NoAssistanceFromCareOption: [],
        PlaceOfConsultation: [],
        CarePsych: [],
      },
    };

    this.dataContainer = new DataContainer();
    this.initConstants();
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    reqwest({
      method: 'get',
      url: settings.statistic.get,
      data: {
        ClientGuid: this.clientGuid,
      },
    })
      .then(async (res) => {
        this.dataContainer.add('Statistic', res);
        this.setState({
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  getPreviousUseOfCareOption = [
    { value: true, label: 'Ja' },
    { value: false, label: 'Nein' },
  ];

  async initConstants() {
    this.setState({
      PreviousUseOfCareOption: this.getPreviousUseOfCareOption,
      RecommendedInterventionOption: await getConstants(
        'RecommendedInterventionOption',
      ),
      NoAssistanceFromCareOption: await getConstants(
        'NoAssistanceFromCareOption',
      ),
      ArrangementOfPsychotherapyThroughCare: await getConstants(
        'ArrangementOfPsychotherapyThroughCare',
      ),
      CarePsychologistLocation: await getConstants('Location'),
      RegionalAssignmentOfTheCase: await getConstants('Location'),
    });
  }

  handleSelectedOption = (name, value) => {
    this.setState({
      selectedOption: {
        ...this.state.selectedOption,
        [name]: value,
      },
    });
  };

  save = async () => {
    await reqwest({
      url: settings.statistic.save,
      method: 'POST',
      data: {
        PreviousUseOfCareOption:
          this.state.selectedOption.PreviousUseOfCareOption,

        RecommendedInterventionOption: JSON.stringify(
          this.state.selectedOption.RecommendedInterventionOption,
        ),
        NoAssistanceFromCareOption:
          this.state.selectedOption.NoAssistanceFromCareOption,

        ArrangementOfPsychotherapyThroughCare:
          this.state.selectedOption.ArrangementOfPsychotherapyThroughCare,
        CarePsychologistLocation:
          this.state.selectedOption.CarePsychologistLocation,
        RegionalAssignmentOfTheCase:
          this.state.selectedOption.RegionalAssignmentOfTheCase,
        ClientGuid: this.clientGuid,
      },
    })
      .then((_) => {
        swal({
          title: 'Gespeichert!',
          text: 'Erfolgreich gespeichert.',
          icon: 'success',
        });
        this.props.history.goBack();
      })
      .catch(() => {
        swal({
          title: 'Speichern nicht möglich!',
          text: 'Es ist ein Fehler beim speichern aufgetreten',
          icon: 'error',
        });
      });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            <Link to="/clientmanagement">Klientenverwaltung</Link> -{' '}
            <Link
              onClick={() => {
                this.props.history.push(
                  `/clientmanagement/detail/${this.clientGuid}`,
                );
              }}
            >
              Klient
            </Link>
            {' - '}
            <Link to={this.parentRoute}>Statistik</Link> - <b>Neue Statistik</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton
              icon={faTimes}
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              Abbrechen
            </HeaderbarButton>
          </div>
        </div>
        <div className="Split-Panes-Menu">
          <div className="Split-Pane-Menu">
            <div className="Split-Pane-Menu-Nav">
              <Button onClick={() => this.props.history.goBack()}>
                <FontAwesomeIcon icon={faArrowLeft} /> &ensp; Zurück
              </Button>
            </div>
          </div>

          <div className="Split-Pane-Main">
            <div className="Page-Content">
              <ContentBox title="Letzter Statistikdaten">
                <Dropdown
                  label="Vorherige Inanspruchnahme von CARE:"
                  options={this.state.PreviousUseOfCareOption}
                  isDisabled
                  defaultValue={this.dataContainer.get(
                    'Statistic',
                    'previousUseOfCareOption',
                  )}
                  validator={[
                    {
                      required: true,
                      error: 'Pflichtfeld ist nicht ausgefüllt.',
                    },
                  ]}
                  name="PreviousUseOfCAREOption"
                />
                <Dropdown
                  name="RecommendedIntervention"
                  label="Empfohlene Intervention (Eingabefunktion) NEU"
                  isDisabled
                  isMulti
                  isClearable
                  isSearchable
                  validator={[
                    {
                      required: true,
                      error: 'Pflichtfeld ist nicht ausgefüllt.',
                    },
                  ]}
                  options={this.state.RecommendedInterventionOption}
                  defaultValue={this.dataContainer.get(
                    'Statistic',
                    'recommendedInterventionOption',
                  )}
                />
                <Dropdown
                  name="ArrangementOfPsychotherapyThroughCare"
                  label="Vermittlung einer Psychotherapie durch CARE"
                  options={this.state.ArrangementOfPsychotherapyThroughCare}
                  isDisabled
                  defaultValue={this.dataContainer.get(
                    'Statistic',
                    'arrangementOfPsychotherapyThroughCare',
                  )}
                  validator={[
                    {
                      required: true,
                      error: 'Pflichtfeld ist nicht ausgefüllt.',
                    },
                  ]}
                />
                <Dropdown
                  name="NoAssistanceFromCare"
                  label="Keine Hilfe durch CARE"
                  options={this.state.NoAssistanceFromCareOption}
                  isDisabled
                  defaultValue={this.dataContainer.get(
                    'Statistic',
                    'noAssistanceFromCareOption',
                  )}
                  validator={[
                    {
                      required: true,
                      error: 'Pflichtfeld ist nicht ausgefüllt.',
                    },
                  ]}
                />
                <Dropdown
                  name="CarePsychologistLocation"
                  label="Standort der CARE-Psychologin"
                  options={this.state.CarePsychologistLocation}
                  isDisabled
                  defaultValue={this.dataContainer.get(
                    'Statistic',
                    'carePsychologistLocation',
                  )}
                  validator={[
                    {
                      required: true,
                      error: 'Pflichtfeld ist nicht ausgefüllt.',
                    },
                  ]}
                />
                <Dropdown
                  name="RegionalAssignmentOfTheCase"
                  label="Regionale Zuordnung des Falles"
                  isDisabled
                  options={this.state.RegionalAssignmentOfTheCase}
                  defaultValue={this.dataContainer.get(
                    'Statistic',
                    'regionalAssignmentOfTheCase',
                  )}
                  validator={[
                    {
                      required: true,
                      error: 'Pflichtfeld ist nicht ausgefüllt.',
                    },
                  ]}
                />
              </ContentBox>
              <ContentBox title="Statistikdaten">
                <Dropdown
                  label="Vorherige Inanspruchnahme von CARE:"
                  options={this.state.PreviousUseOfCareOption}
                  getSelected={(value) => {
                    return this.handleSelectedOption(
                      'PreviousUseOfCareOption',
                      value,
                    );
                  }}
                  validator={[
                    {
                      required: true,
                      error: 'Pflichtfeld ist nicht ausgefüllt.',
                    },
                  ]}
                  name="PreviousUseOfCAREOption"
                />
                <Dropdown
                  name="RecommendedIntervention"
                  label="Empfohlene Intervention (Eingabefunktion) NEU"
                  info="Mehrfachauswahl"
                  isMulti
                  isClearable
                  isSearchable
                  validator={[
                    {
                      required: true,
                      error: 'Pflichtfeld ist nicht ausgefüllt.',
                    },
                  ]}
                  options={this.state.RecommendedInterventionOption}
                  getSelected={(values) =>
                    this.handleSelectedOption(
                      'RecommendedInterventionOption',
                      values,
                    )
                  }
                />
                <Dropdown
                  name="ArrangementOfPsychotherapyThroughCare"
                  label="Vermittlung einer Psychotherapie durch CARE"
                  options={this.state.ArrangementOfPsychotherapyThroughCare}
                  getSelected={(values) =>
                    this.handleSelectedOption(
                      'ArrangementOfPsychotherapyThroughCare',
                      values,
                    )
                  }
                  validator={[
                    {
                      required: true,
                      error: 'Pflichtfeld ist nicht ausgefüllt.',
                    },
                  ]}
                />
                <Dropdown
                  name="NoAssistanceFromCare"
                  label="Keine Hilfe durch CARE"
                  options={this.state.NoAssistanceFromCareOption}
                  getSelected={(values) =>
                    this.handleSelectedOption(
                      'NoAssistanceFromCareOption',
                      values,
                    )
                  }
                  validator={[
                    {
                      required: true,
                      error: 'Pflichtfeld ist nicht ausgefüllt.',
                    },
                  ]}
                />
                <Dropdown
                  name="CarePsychologistLocation"
                  label="Standort der CARE-Psychologin"
                  options={this.state.CarePsychologistLocation}
                  getSelected={(values) =>
                    this.handleSelectedOption(
                      'CarePsychologistLocation',
                      values,
                    )
                  }
                  validator={[
                    {
                      required: true,
                      error: 'Pflichtfeld ist nicht ausgefüllt.',
                    },
                  ]}
                />
                <Dropdown
                  name="RegionalAssignmentOfTheCase"
                  label="Regionale Zuordnung des Falles"
                  options={this.state.RegionalAssignmentOfTheCase}
                  getSelected={(values) =>
                    this.handleSelectedOption(
                      'RegionalAssignmentOfTheCase',
                      values,
                    )
                  }
                  validator={[
                    {
                      required: true,
                      error: 'Pflichtfeld ist nicht ausgefüllt.',
                    },
                  ]}
                />
                <div className="exportStatistic-button-area">
                  <Button
                    icon={faSave}
                    onClick={() => this.save()}
                    type="primary"
                  >
                    Speichern
                  </Button>
                </div>
              </ContentBox>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(CaseStatisticsNewEntry);
