import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import ConsultationOverview from './Overview';
import ConsultationNewEntry from './New';
import ConsultationDetail from './Detail';

const Consultation = (props) => {
  const paths = {
    new: `${props.match.path}/new/:clientGuid`,
    details: `${props.match.path}/detail/:guid/:clientGuid`,
    overview: `${props.match.path}`,
  };

  return (
    <Switch>
      <Route path={paths.new} component={ConsultationNewEntry} />
      <Route path={paths.details} component={ConsultationDetail} />
      <Route path={paths.overview} component={ConsultationOverview} />
    </Switch>
  );
};

export default withRouter(Consultation);
