import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import reqwest from 'reqwest';
import swal from '@sweetalert/with-react';
import {
  faArrowLeft,
  faSave,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons';
import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';
import Loader from '../../../../../components/Loader';
import Form from '../../../../../components/Form';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Inputs/Input';
import Dropdown from '../../../../../components/Inputs/Dropdown';
import getConstants from '../../../../../logic/constants';
import HeaderbarButton from '../../../../../components/HeaderbarButton';
import moment from 'moment';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
class ConsultationDetail extends Component {
  constructor(props) {
    super(props);
    this.clientGuid = props.match.params.clientGuid;
    this.guid = props.match.params.guid;
    this.parentRoute = this.props.match.url.replace(
      /\/consultation\/.*/,
      '/consultation/' + this.clientGuid,
    );

    this.state = {
      loading: true,
      PlaceOfConsultation: [],
      TypeOfConsultation: [],
      KindOfConsultation: [],
      CarePsych: [],
    };
    this.initConstants();
  }

  async initConstants() {
    this.setState({
      PlaceOfConsultation: await getConstants('PlaceOfConsultation'),
      TypeOfConsultation: await getConstants('TypeOfConsultation'),
      KindOfConsultation: await getConstants('CounselingContactKind'),
      CarePsych: await getConstants('CarePsych'),
    });
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    reqwest({
      method: 'get',
      url: settings.clientmanagement.dataContainer.get,
      data: {
        guid: this.guid,
      },
    })
      .then((res) => {
        this.dataContainer = new DataContainer(res);
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        console.warn('catch res:', e);
        this.setState({
          loading: false,
        });
      });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Fehler bei Ihren Eingaben',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.',
      });
      return;
    }

    // TODO: save in Array/List after middelware changed it.
    const oneDataContainer = JSON.stringify({
      ParamName: 'Consultation',
      Params: this.dataContainer.get('Entry'),
    });

    reqwest({
      method: 'POST',
      url: settings.clientmanagement.dataContainer.save,
      data: {
        container: oneDataContainer,
        // container: this.dataContainer.getStringified(), // TODO: use this when in middelware fixed! DataContain should always be in Array/List
        action: 'Wiedervorlage bearbeitet',
      },
    })
      .then(() => {
        swal({
          title: 'Gespeichert!',
          text: 'Erfolgreich gespeichert.',
          icon: 'success',
        });
        this.props.history.goBack();
      })
      .catch(() => {
        swal({
          title: 'Speichern nicht möglich!',
          text: 'Es ist ein Fehler beim speichern aufgetreten',
          icon: '',
        });
      });
  };

  remove = async () => {
    swal({
      title: 'Löschen?',
      text: 'Möchten Sie wirklich diese Beratung löschen?',
      buttons: {
        cancle: 'nein',
        delete: 'ja',
      },
    }).then((value) => {
      if (value === 'delete') {
        reqwest({
          method: 'POST',
          url: settings.clientmanagement.dataContainer.remove,
          data: {
            guid: this.guid,
            action: 'Beratung gelöscht', // needed for history

            clientGuid: this.dataContainer.get('Entry', 'ClientGuid'), // needed for history
          },
        })
          .then(() => {
            swal({
              title: 'Gelöscht!',
              text: 'Erfolgreich gelöscht.',
              icon: 'success',
            });
            this.props.history.replace(this.parentRoute);
          })
          .catch(() => {
            swal({
              title: 'Löschen nicht möglich!',
              text: 'Es ist ein Fehler beim löschen aufgetreten',
              icon: 'error',
            });
          });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            <Link to="/clientmanagement">Klientenverwaltung</Link> -{' '}
            <Link
              to="/#"
              onClick={() => {
                this.props.history.push(
                  `/clientmanagement/detail/${this.dataContainer.get(
                    'Entry',
                    'ClientGuid',
                  )}`,
                );
              }}
            >
              Klient
            </Link>
            {' - '}
            <Link to={this.parentRoute}>Beratungsdokumentation</Link> -{' '}
            <b>Bearbeiten</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton trash icon={faTrashAlt} onClick={this.remove}>
              löschen
            </HeaderbarButton>
          </div>
        </div>
        <div className="Split-Panes-Menu">
          <div className="Split-Pane-Menu">
            <div className="Split-Pane-Menu-Nav">
              <NavLink className="FormArea-Body-Tab" to={this.parentRoute}>
                <FontAwesomeIcon icon={faArrowLeft} /> Zurück
              </NavLink>
            </div>
          </div>
          <div className="Split-Pane-Main">
            <div className="Page-Content">
              <div className="Buttonbar">
                <Button icon={faSave} onClick={this.save}>
                  Speichern
                </Button>
              </div>
              <Form
                ref={(node) => {
                  return (this.form = node);
                }}
              >
                <ContentBox title="Beratungsdokumentation">
                  <Input
                    type="date"
                    validator={[
                      {
                        required: true,
                        error: 'Bitte Datum eingeben',
                      },
                      {
                        date: true,
                        error: 'Bitte Datum im Format DD.MM.YYYY eingeben',
                      },
                    ]}
                    onChange={(value) => {
                      this.dataContainer.set(
                        'Entry',
                        'DateOfConsultation',
                      )(moment(value)?.format('YYYY-MM-DD'));
                    }}
                    defaultValue={moment(
                      this.dataContainer.get('Entry', 'DateOfConsultation'),
                    )?.format('YYYY-MM-DD')}
                    label="Datum der Beratung"
                    name="DateOfConsultation"
                  />
                  <Input
                    type="number"
                    validator={[]}
                    onChange={(value) => {
                      this.dataContainer.set('Entry', 'Duration')(value);
                    }}
                    defaultValue={this.dataContainer.get('Entry', 'Duration')}
                    label="Dauer der Beratung in Minuten"
                    name="Duration"
                  />
                  <Dropdown
                    label="Form der Beratung:"
                    getSelected={(value) => {
                      this.dataContainer.set(
                        'Entry',
                        'KindOfConsultation',
                      )(value);
                    }}
                    defaultValue={this.dataContainer.get(
                      'Entry',
                      'KindOfConsultation',
                    )}
                    options={this.state.KindOfConsultation}
                    validator={[]}
                    name="KindOfConsultation"
                  />
                  <Dropdown
                    label="Standort"
                    validator={[]}
                    getSelected={(value) => {
                      this.dataContainer.set(
                        'Entry',
                        'PlaceOfConsultation',
                      )(value);
                    }}
                    defaultValue={this.dataContainer.get(
                      'Entry',
                      'PlaceOfConsultation',
                    )}
                    options={this.state.PlaceOfConsultation}
                    name="PlaceOfConsultation"
                  />
                  <Dropdown
                    label="CARE-Psychologe:"
                    getSelected={(value) => {
                      this.dataContainer.set('Entry', 'CarePsych')(value);
                    }}
                    defaultValue={this.dataContainer.get('Entry', 'CarePsych')}
                    options={this.state.CarePsych}
                    validator={[]}
                    name="CarePsych"
                  />
                  <Dropdown
                    label="Art der Beratung:"
                    getSelected={(value) => {
                      this.dataContainer.set(
                        'Entry',
                        'TypeOfConsultation',
                      )(value);
                    }}
                    defaultValue={this.dataContainer.get(
                      'Entry',
                      'TypeOfConsultation',
                    )}
                    options={this.state.TypeOfConsultation}
                    validator={[]}
                    name="TypeOfConsultation"
                  />
                </ContentBox>
              </Form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ConsultationDetail);
