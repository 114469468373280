import React, { Component } from 'react';
import ReactTable from 'react-table';
import api from '../../../../../logic/api';
import {
  faEdit,
  faPlus,
  faArrowLeft,
  faRepeat,
} from '@fortawesome/pro-regular-svg-icons';
import Loader from '../../../../../components/Loader';
import Button from '../../../../../components/Button';
import HeaderbarButton from '../../../../../components/HeaderbarButton';
import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

class ResubmissionOverview extends Component {
  constructor(props) {
    super(props);
    this.clientGuid = props.match.params.clientGuid;
    this.parentRoute = this.props.match.url.replace(
      new RegExp('/resubmission/.*'),
      '',
    );

    this.state = {
      loading: true,
      data: [],
    };
  }

  loadData = () => {
    this.setState({
      data: [],
      loading: true,
    });
    const dataColumns = {
      Typ: 'Resubmission',
      ClientGuid: this.clientGuid,
    };
    api('get', settings.clientmanagement.dataContainer.getList, {
      parameter: JSON.stringify(dataColumns),
    })
      .then((res) => {
        const filteredClientData = new DataContainer(res);
        this.setState({
          data: filteredClientData.getMany('Entry'),
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  };

  componentDidMount() {
    this.loadData();
  }

  render() {
    if (this.state.loading) {
      return (
        <div>
          <Loader text="Wiedervorlagen werden geladen..." />
        </div>
      );
    }

    let data = [];
    if (
      this.state.data.filter((wv) => {
        return wv.Guid;
      }).length
    ) {
      data = this.state.data;
    }

    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            <Link to="/clientmanagement">Klientenverwaltung</Link> -{' '}
            <Link
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              Klient
            </Link>{' '}
            - <b>Wiedervorlage</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton icon={faRepeat} onClick={this.loadData}>
              Liste aktualisieren
            </HeaderbarButton>
          </div>
        </div>

        <div className="Split-Panes-Menu">
          <div className="Split-Pane-Menu">
            <div className="Split-Pane-Menu-Nav">
              <NavLink className="FormArea-Body-Tab" to="/">
                <FontAwesomeIcon icon={faArrowLeft} /> Zurück zur Übersicht
              </NavLink>
              <NavLink
                to={`${this.parentRoute}/${this.clientGuid}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Grunddaten
              </NavLink>
              <NavLink
                to={`${this.parentRoute}/consultation/${this.clientGuid}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Dokumentation
              </NavLink>
              <NavLink
                to={`${this.parentRoute}/caseStatistics/new/${this.clientGuid}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Statistik
              </NavLink>
              <NavLink
                to={`${this.parentRoute}/appointments/${this.clientGuid}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Terminverwaltung
              </NavLink>
              <NavLink
                to={`${this.parentRoute}/resubmission/${this.clientGuid}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Wiedervorlage
              </NavLink>
              <NavLink
                to={`${this.parentRoute}/history/${this.clientGuid}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Historie
              </NavLink>
            </div>
          </div>
          <div className="Split-Pane-Main">
            <div className="Page-Content">
              <div className="Buttonbar">
                <Button
                  icon={faPlus}
                  to={`${this.parentRoute}/resubmission/new/${this.clientGuid}`}
                  replace
                >
                  Neue Wiedervorlage anlegen
                </Button>
              </div>
              <ReactTable
                getTrProps={(_, val) => {
                  return {
                    onClick: () => {
                      return this.props.history.push(
                        `/clientmanagement/detail/resubmission/detail/${val?.original?.Guid}/${this.clientGuid}`,
                      );
                    },
                  };
                }}
                defaultSorted={[
                  {
                    id: 'UpdateUserName',
                    desc: true,
                  },
                ]}
                data={data}
                pageSize={data.length}
                noDataText="Es wurden keine Wiedervorlagen gefunden."
                columns={[
                  {
                    accessor: 'DateOfResub',
                    Header: 'Datum der Wiedervorlage',
                    minWidth: 250,
                    maxWidth: 250,
                    Cell: ({ original }) => {
                      return (
                        <>{moment(original.DateOfResub).format('DD.MM.YYYY')}</>
                      );
                    },
                    sortMethod: (a, b) => {
                      return moment(a).isAfter(b);
                    },
                  },
                  {
                    accessor: 'Reason',
                    Header: 'Grund',
                    Cell: ({ original }) => {
                      return <>{original.Reason.replace('<br />', ' ')}</>;
                    },
                  },
                  {
                    accessor: 'UpdateUserName',
                    Header: 'Mitarbeiter',
                    Cell: ({ original }) => {
                      return <>{original.UpdateUserName}</>;
                    },
                  },
                  {
                    accessor: 'Link',
                    Header: 'Zur Detailansicht',
                    Cell: () => {
                      return (
                        <>
                          <u>Hier klicken</u>&nbsp;
                          <FontAwesomeIcon icon={faEdit} />
                        </>
                      );
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ResubmissionOverview;
