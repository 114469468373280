import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import reqwest from 'reqwest';
import swal from '@sweetalert/with-react';
import { faSave, faTimes } from '@fortawesome/pro-regular-svg-icons';
import mbxGeocoder from '@mapbox/mapbox-sdk/services/geocoding';
import settings from '../../../../settings';
import DataContainer from '../../../logic/dataContainer';
import Button from '../../../components/Button';
import HeaderbarButton from '../../../components/HeaderbarButton';
import AmbPsychForm from './AmbPsychForm';
import moment from 'moment';

class SupplynetworkAmbulatoryPsychotherapyNew extends Component {
  constructor(props) {
    super(props);
    this.parentRoute = this.props.match.url.replace(new RegExp('/new'), '');

    this.geocodingClient = mbxGeocoder({
      accessToken: settings.external.mapbox_key,
    });

    this.state = {
      saving: false,
      positions: [],
      Gender: [],
      dateOfResub: '',
    };
  }

  locationSearch = async (value) => {
    return this.geocodingClient
      .forwardGeocode({
        query: value,
      })
      .send()
      .then((response) => {
        const match = response.body;
        if (!match.features.length) return [null, null];
        return match.features[0].center;
      });
  };

  updateCoordinates = async (office /*'FirstOffice' or 'SecondOffice' */) => {
    const street = this.form.dataContainer.get(office, 'Street');
    const zip = this.form.dataContainer.get(office, 'ZIP');
    const city = this.form.dataContainer.get(office, 'City');
    if (zip || city) {
      const query = (street ? street + ', ' : '') + zip + ' ' + city;
      const location = await this.locationSearch(query);
      this.form.dataContainer.set(office, 'ZipLon')(location[0]);
      this.form.dataContainer.set(office, 'ZipLat')(location[1]);
    }
  };

  save = async () => {
    this.setState({ saving: true });
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Fehler bei Ihren Eingaben',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.',
      });
      this.setState({ saving: false });
      return;
    }
    this.form.dataContainer.set(
      'TransferDb',
      'Date',
    )(moment().format('DD-MM-YYYY'));
    const slotsExpire = this.form.dataContainer.get(
      'Availability',
      'SlotsExpire',
    );
    await this.updateCoordinates('FirstOffice');
    await this.updateCoordinates('SecondOffice');
    const data = this.form.dataContainer.getStringified();

    reqwest({
      method: 'POST',
      url: settings.supplynetwork.ambulatoryPsychotherapy.save,
      data: {
        Token: localStorage.getItem('token'),
        AmbulatoryPsychotherapy: data,
      },
    })
      .then(async (res) => {
        swal({
          title: 'Gespeichert!',
          text: 'Die Adresse wurde erfolgreich gespeichert.',
          icon: 'success',
        });
        // Add resubmission if expire date is set as a reminder to review the marked slots.
        if (res && slotsExpire) {
          if (res === 'No Data') return false;
          res = [JSON.parse(res)];
          const resDC = new DataContainer(res);
          const newAmbPsyGuid = resDC.get(
            'SaveAmbulytoryPsychotherapy',
            'Guid',
          );
          await this.addResubmission(newAmbPsyGuid, slotsExpire);
        }
        this.setState({ saving: false });
        this.props.history.replace(this.parentRoute);
      })
      .catch(() => {
        swal({
          title: 'Speichern fehlgeschlagen!',
          text: 'Es ist ein Fehler beim speichern aufgetreten',
          icon: '',
        });
        this.setState({ saving: false });
      });
  };

  async addResubmission(ambPsyGuid, dateOfResub) {
    const resubmissionDataContainer = JSON.stringify({
      ParamName: 'Resubmission',
      Params: {
        Typ: 'Resubmission',
        AmbPsyGuid: ambPsyGuid,
        DateOfResub: dateOfResub,
        Reason: 'Angaben zur tägliche Verfügbarkeit abgelaufen',
      },
    });
    await reqwest({
      method: 'POST',
      url: settings.clientmanagement.dataContainer.save,
      data: {
        container: resubmissionDataContainer,
        action: 'Neuen Wiedervorlage angelegt',
      },
    })
      .then((_) => {
        swal({
          title: 'Gespeichert!',
          text: 'Erfolgreich gespeichert.',
          icon: 'success',
        });
        return Promise.resolve();
      })
      .catch(() => {
        swal({
          title: 'Speichern der Wiedervorlage fehlgeschlagen!',
          text: 'Es ist ein Fehler beim speichern der Wiedervorlage aufgetreten',
          icon: '',
        });
        return Promise.reject();
      });
  }

  render() {
    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            Versorgungsnetzwerk -{' '}
            <Link to="/supplynetwork/AmbulatoryPsychotherapy">
              Ambulante Psychotherapie
            </Link>{' '}
            - <b>Neu</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton icon={faTimes} to={this.parentRoute}>
              Abbrechen
            </HeaderbarButton>
          </div>
        </div>
        <div className="Page-Content">
          <div className="Buttonbar">
            <Button
              type="primary"
              icon={faSave}
              onClick={this.save}
              loading={this.state.saving}
            >
              Speichern
            </Button>
          </div>
          <AmbPsychForm
            ref={(node) => {
              return (this.form = node);
            }}
          />
          <div className="Buttonbar-bottom">
            <Button
              className="Button-bigger"
              loading={this.state.saving}
              type="primary"
              icon={faSave}
              onClick={this.save}
              outline
            >
              Speichern
            </Button>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(SupplynetworkAmbulatoryPsychotherapyNew);
