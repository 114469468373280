import React, { Component } from 'react';
import ReactTable from 'react-table';
import {
  faPlus,
  faEdit,
  faArrowLeft,
  faRepeat,
} from '@fortawesome/pro-regular-svg-icons';
import Loader from '../../../../../components/Loader';
import Button from '../../../../../components/Button';
import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import getConstants from '../../../../../logic/constants';
import api from '../../../../../logic/api';
import HeaderbarButton from '../../../../../components/HeaderbarButton';
class AppointmentOverview extends Component {
  constructor(props) {
    super(props);

    this.clientGuid = props.match.params.clientGuid;
    this.parentRoute = this.props.match.url.replace(
      new RegExp('/appointments/.*'),
      '',
    );

    this.state = {
      loading: true,
      data: [],
      CarePsych: [],
      PlaceOfConsultation: [],
    };
    this.initConstants();
  }

  async initConstants() {
    this.setState({
      CarePsych: await getConstants('CarePsych'),
      PlaceOfConsultation: await getConstants('PlaceOfConsultation'),
    });
  }

  loadData = () => {
    this.setState({
      data: [],
      loading: true,
    });
    const dataColumns = {
      Typ: 'Appointment',
      ClientGuid: this.clientGuid,
    };
    api('get', settings.clientmanagement.dataContainer.getList, {
      parameter: JSON.stringify(dataColumns),
    })
      .then((res) => {
        const filteredClientData = new DataContainer(res);
        this.setState({
          data: filteredClientData.getMany('Entry'),
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  };

  componentDidMount() {
    this.loadData();
  }

  render() {
    if (this.state.loading) {
      return (
        <div>
          <Loader text="Termine werden geladen..." />
        </div>
      );
    }

    let data = [];
    if (
      this.state.data.filter((a) => {
        return a.Guid;
      }).length
    ) {
      data = this.state.data;
    }

    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            <Link to="/clientmanagement">Klientenverwaltung</Link> -{' '}
            <Link
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              Klient
            </Link>{' '}
            - <b>Beratungstermin</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton icon={faRepeat} onClick={this.loadData}>
              Liste aktualisieren
            </HeaderbarButton>
          </div>
        </div>

        <div className="Split-Panes-Menu">
          <div className="Split-Pane-Menu">
            <div className="Split-Pane-Menu-Nav">
              <NavLink className="FormArea-Body-Tab" to="/">
                <FontAwesomeIcon icon={faArrowLeft} /> Zurück zur Übersicht
              </NavLink>
              <NavLink
                to={`${this.parentRoute}/${this.clientGuid}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Grunddaten
              </NavLink>
              <NavLink
                to={`${this.parentRoute}/consultation/${this.clientGuid}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Dokumentation
              </NavLink>
              <NavLink
                to={`${this.parentRoute}/caseStatistics/new/${this.clientGuid}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Statistik
              </NavLink>
              <NavLink
                to={`${this.parentRoute}/appointments/${this.clientGuid}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Terminverwaltung
              </NavLink>
              <NavLink
                to={`${this.parentRoute}/resubmission/${this.clientGuid}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Wiedervorlage
              </NavLink>
              <NavLink
                to={`${this.parentRoute}/history/${this.clientGuid}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Historie
              </NavLink>
            </div>
          </div>
          <div className="Split-Pane-Main">
            <div className="Page-Content">
              <div className="Buttonbar">
                <Button
                  icon={faPlus}
                  to={`${this.parentRoute}/appointments/new/${this.clientGuid}`}
                  replace
                >
                  Neuen Beratungstermin anlegen
                </Button>
              </div>
              <ReactTable
                getTrProps={(_, val) => {
                  return {
                    onClick: () => {
                      return this.props.history.push(
                        `/clientmanagement/detail/appointments/detail/${val?.original?.Guid}/${this.clientGuid}`,
                      );
                    },
                  };
                }}
                defaultSorted={[
                  {
                    id: 'DateOfAppointment',
                    desc: true,
                  },
                ]}
                data={data}
                pageSize={data.length}
                noDataText="Es wurden keine Termine gefunden."
                columns={[
                  {
                    accessor: 'DateOfAppointment',
                    Header: 'Datum der Beratung',
                    Cell: ({ original }) => {
                      return (
                        <>
                          {moment(original.DateOfAppointment).format(
                            'DD.MM.YYYY',
                          )}
                        </>
                      );
                    },
                  },
                  {
                    accessor: 'TimeOfAppointment',
                    Header: 'Uhrzeit',
                    Cell: ({ original }) => {
                      return <>{original.TimeOfAppointment}</>;
                    },
                  },
                  {
                    accessor: 'PlaceOfConsultation',
                    Header: 'Beratungsort',
                    Cell: ({ original }) => {
                      return (
                        <>
                          {
                            this.state.PlaceOfConsultation.find((elem) => {
                              return (
                                elem.value === original.PlaceOfConsultation
                              );
                            })?.label
                          }
                        </>
                      );
                    },
                  },
                  {
                    accessor: 'CarePsych',
                    Header: 'CARE-Psych.',
                    Cell: ({ original }) => {
                      return (
                        <>
                          {
                            this.state.CarePsych.find((elem) => {
                              return elem.value === original.CarePsych;
                            })?.label
                          }
                        </>
                      );
                    },
                  },
                  {
                    accessor: 'Link',
                    Header: 'Zur Detailansicht',
                    Cell: () => {
                      return (
                        <>
                          <u>Hier klicken</u>&nbsp;
                          <FontAwesomeIcon icon={faEdit} />
                        </>
                      );
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AppointmentOverview;
