import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import reqwest from 'reqwest';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';
import Loader from '../../../../../components/Loader';
import HeaderbarButton from '../../../../../components/HeaderbarButton';
import AmbPsychForm from '../../../../Supplynetwork/AmbulatoryPsychotherapy/AmbPsychForm';
import Button from '../../../../../components/Button';

class SelectAmbulatoryPsychotherapyDetail extends Component {
  dataContainer;
  constructor(props) {
    super(props);

    this.ambulPsychGuid = props.match.params.guid;
    this.psychName = props.match.params.psychName;

    this.clientGuid = props.match.params.clientGuid;
    this.clientName = props.match.params.clientName;

    this.state = {
      loading: true,
      positions: [],
    };
  }

  componentDidMount() {
    this.loadPsychologist();
  }

  componentDidUpdate(prevProp) {
    if (prevProp.match.params.guid !== this.props.match.params.guid) {
      this.loadPsychologist();
    }
  }

  loadPsychologist = () => {
    this.setState({
      loading: true,
    });
    reqwest({
      method: 'get',
      url: settings.supplynetwork.ambulatoryPsychotherapy.get,
      data: {
        Token: localStorage.getItem('token'),
        Guid: this.props.match.params.guid,
      },
    })
      .then((res) => {
        this.dataContainer = new DataContainer(res);
        this.migrateOldData();
        this.setState({
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  select = async () => {
    this.props.history.push(
      `/clientmanagement/detail/selectCounselling/print/${this.clientGuid}/ambulPsych/${this.ambulPsychGuid}/${this.psychName}/${this.clientName}`,
    );
  };

  migrateOldData() {
    /** TherapyProcedures from dropdown to multiselect => string to array (8.Dec.22) */
    const therapyProcedures = this.dataContainer.get(
      'AmbulantPsychotherapy',
      'TherapyProcedures',
    );
    if (typeof therapyProcedures === 'string') {
      this.dataContainer.set(
        'AmbulantPsychotherapy',
        'TherapyProcedures',
      )([therapyProcedures]);
    }
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    if (!this.dataContainer) {
      return (
        <>
          <div className="Headerbar sticky">
            <div className="Headerbar-breadcrumbs">
              <Link to="/clientmanagement">Klientenverwaltung</Link>
              {' - '}
              <Link to={`/clientmanagement/detail/${this.clientGuid}`}>
                Klient &quot;{this.clientName}&quot;
              </Link>
              {' - '}
              <Link
                to={`/clientmanagement/detail/selectCounselling/SelectAmbulatoryPsychotherapyOverview/${this.clientGuid}/${this.clientName}`}
              >
                Therapeut/in auswählen oder suchen
              </Link>
              {' - '} <b>&quot;{this.psychName}&quot;</b>
            </div>
            <div className="Headerbar-buttons"></div>
          </div>
          <div className="Page-Content">
            Therapeut/in konnte nicht geladen werden...
          </div>
        </>
      );
    }

    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            <Link to="/clientmanagement">Klientenverwaltung</Link>
            {' - '}
            <Link to={`/clientmanagement/detail/${this.clientGuid}`}>
              Klient &quot;{this.clientName}&quot;
            </Link>
            {' - '}
            <Link
              to={`/clientmanagement/detail/selectCounselling/SelectAmbulatoryPsychotherapyOverview/${this.clientGuid}/${this.clientName}`}
            >
              Therapeut/in auswählen oder suchen
            </Link>
            {' - '} <b>&quot;{this.psychName}&quot;</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton icon={faLink} onClick={this.select}>
              Formularservice öffnen
            </HeaderbarButton>
          </div>
        </div>
        <div className="Page-Content">
          <Button
            onClick={() => {
              history.back();
            }}
          >
            Zurück
          </Button>
          <AmbPsychForm
            ref={(node) => {
              return (this.form = node);
            }}
            isDisabled
            dataContainer={this.dataContainer}
          />
        </div>
      </>
    );
  }
}

export default SelectAmbulatoryPsychotherapyDetail;
