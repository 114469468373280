import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';
import { faTimes, faSave } from '@fortawesome/pro-regular-svg-icons';
import settings from '../../../../../settings';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import Editor from '../../../../components/Editor';
import Input from '../../../../components/Inputs/Input';
import ContentBox from '../../../../components/FormArea/ContentBox';
import DataContainer from '../../../../logic/dataContainer';

class EMailTemplateNew extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(/\/new$/, '');

    this.state = {
      loading: false,
      templatePlaceholder: [
        {
          title: 'Klient',
          icon: 'user',
          value: [
            { key: 'KLIENT_ANREDE', value: 'Klient Anrede' },
            { key: 'KLIENT_VORNAME', value: 'Klient Vorname' },
            { key: 'KLIENT_NACHNAME', value: 'Klient Nachname' },
            { key: 'KLIENT_PLZ', value: 'Klient PLZ' },
            { key: 'KLIENT_ORT', value: 'Klient Ort' },
            { key: 'KLIENT_STRASSE', value: 'Klient Straße' },
          ],
        },
        {
          title: 'Bearbeiter',
          icon: 'building',
          value: [
            { key: 'BEARBEITER_ANREDE', value: 'Bearbeiter Anrede' },
            { key: 'BEARBEITER_NACHNAME', value: 'Bearbeiter Nachname' },
            { key: 'BEARBEITER_ORT', value: 'Bearbeiter Ort' },
            { key: 'BEARBEITER_EMAIL', value: 'Bearbeiter E-Mail' },
            {
              key: 'BEARBEITER_TELEFONVORW',
              value: 'Bearbeiter Telefonvorwahl',
            },
            { key: 'BEARBEITER_TELEFON', value: 'Bearbeiter Telefon' },
            { key: 'OfficeStreet', value: 'Bearbeiter Straße' },
          ],
        },
        {
          title: 'Test',
          icon: 'cogs',
          value: [
            { key: 'TestName', value: 'Test Name' },
            { key: 'TestPlace', value: 'Test Ort' },
            { key: 'TestZip', value: 'Test PLZ' },
            { key: 'TestPlace', value: 'Test Ort' },
            { key: 'TestStreet', value: 'Test Straße' },
          ],
        },
      ],
    };

    this.dataContainer = new DataContainer();
    this.dataContainer.add('Basic', {
      Guid: '',
      Typ: 'EMailTemplate',
    });
    this.dataContainer.add('Generals', {
      Title: '',
      Subject: '',
      Body: '',
    });
  }

  save = () => {
    const EmailTemplate = this.dataContainer.getStringified();

    reqwest({
      method: 'POST',
      url: settings.systemmanagement.emailtemplate.save,
      data: {
        Token: localStorage.getItem('token'),
        EmailTemplate,
      },
    }).then(() => {
      swal({
        title: 'Erfolg!',
        text: 'Das E-Mail Template wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className="Page-Content">
          <div className="Buttonbar">
            <Button icon={faTimes} to={this.parentRoute}>
              Abbrechen
            </Button>
            <Button icon={faSave} onClick={this.save}>
              Speichern
            </Button>
          </div>
          <ContentBox title="E-Mail Template">
            <Input
              type="text"
              label="Template Titel:"
              validator={[]}
              onChange={(value) => {
                this.dataContainer.set('Generals', 'Title')(value);
              }}
              defaultValue={this.dataContainer.get('Generals', 'Title')}
              name="Title"
            />
            <Input
              type="text"
              label="Mergefield:"
              validator={[]}
              onChange={(value) => {
                this.dataContainer.set('Generals', 'Subject')(value);
              }}
              defaultValue={this.dataContainer.get('Generals', 'Subject')}
              name="Mergefield"
            />
            <Editor
              onChange={(value) => {
                this.dataContainer.set('Generals', 'Body')(value);
              }}
              editable
              templatePlaceholder={this.state.templatePlaceholder}
              updateConfig={(options) => {
                return {
                  ...options,
                  toolbarButtons: [
                    ...options.toolbarButtons,
                    ...['|'],
                    ...this.state.templatePlaceholder.map((placeholder) => {
                      return placeholder.title;
                    }),
                  ],
                };
              }}
            >
              {this.dataContainer.get('Generals', 'Body')}
            </Editor>
          </ContentBox>
        </div>
      </>
    );
  }
}

export default EMailTemplateNew;
