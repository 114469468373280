import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import reqwest from 'reqwest';
import swal from '@sweetalert/with-react';
import {
  faArrowLeft,
  faSave,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons';
import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';
import Loader from '../../../../../components/Loader';
import Form from '../../../../../components/Form';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Inputs/Input';
import HeaderbarButton from '../../../../../components/HeaderbarButton';
import moment from 'moment';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ResubmissionDetail extends Component {
  constructor(props) {
    super(props);
    this.clientGuid = props.match.params.clientGuid;
    this.guid = props.match.params.guid;
    this.parentRoute = this.props.match.url.replace(
      /\/resubmission\/.*/,
      '/resubmission/' + this.clientGuid,
    );
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    reqwest({
      method: 'get',
      url: settings.clientmanagement.dataContainer.get,
      data: {
        guid: this.guid,
      },
    })
      .then((res) => {
        this.dataContainer = new DataContainer(res);
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        console.warn('catch res:', e);
        this.setState({
          loading: false,
        });
      });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Fehler bei Ihren Eingaben',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.',
      });
      return;
    }

    // TODO: save in Array/List after middelware changed it.
    const oneDataContainer = JSON.stringify({
      ParamName: 'Resubmission',
      Params: this.dataContainer.get('Entry'),
    });

    reqwest({
      method: 'POST',
      url: settings.clientmanagement.dataContainer.save,
      data: {
        container: oneDataContainer,
        // container: this.dataContainer.getStringified(), // TODO: use this when in middelware fixed! DataContain should always be in Array/List
        action: 'Wiedervorlage bearbeitet', // needed for history

        clientGuid: this.dataContainer.get('Entry', 'ClientGuid'), // needed for history
      },
    })
      .then(() => {
        swal({
          title: 'Gespeichert!',
          text: 'Erfolgreich gespeichert.',
          icon: 'success',
        });
      })
      .catch(() => {
        swal({
          title: 'Speichern fehlgeschlagen!',
          text: 'Es ist ein Fehler beim speichern aufgetreten',
          icon: 'error',
        });
      });
  };

  remove = async () => {
    swal({
      title: 'Löschen?',
      buttons: {
        cancle: 'abbrechen',
        delete: 'löschen',
      },
    }).then((value) => {
      if (value === 'delete') {
        reqwest({
          method: 'POST',
          url: settings.clientmanagement.dataContainer.remove,
          data: {
            guid: this.dataContainer.get('Entry', 'Guid'),
            action: 'Wiedervorlage gelöscht', // needed for history

            clientGuid: this.dataContainer.get('Entry', 'ClientGuid'), // needed for history
          },
        })
          .then(() => {
            swal({
              title: 'Gelöscht!',
              text: 'Erfolgreich gelöscht.',
              icon: 'success',
            });
            this.props.history.replace(this.parentRoute);
          })
          .catch(() => {
            swal({
              title: 'Löschen nicht möglich!',
              text: 'Es ist ein Fehler beim löschen aufgetreten',
              icon: 'error',
            });
          });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            <Link to="/clientmanagement">Klientenverwaltung</Link> -{' '}
            <Link
              onClick={() => {
                this.props.history.push(
                  `/clientmanagement/detail/${this.clientGuid}`,
                );
              }}
            >
              Klient
            </Link>{' '}
            - <Link to={this.parentRoute}>Wiedervorlage</Link> -{' '}
            <b>Bearbeiten</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton trash icon={faTrashAlt} onClick={this.remove}>
              löschen
            </HeaderbarButton>
          </div>
        </div>
        <div className="Split-Panes-Menu">
          <div className="Split-Pane-Menu">
            <div className="Split-Pane-Menu-Nav">
              <NavLink className="FormArea-Body-Tab" to={this.parentRoute}>
                <FontAwesomeIcon icon={faArrowLeft} /> Zurück
              </NavLink>
            </div>
          </div>
          <div className="Split-Pane-Main">
            <div className="Page-Content">
              <div className="Buttonbar">
                <Button icon={faSave} onClick={this.save}>
                  Speichern
                </Button>
              </div>
              <Form
                ref={(node) => {
                  return (this.form = node);
                }}
              >
                <ContentBox title="Wiedervorlage">
                  <Input
                    type="date"
                    validator={[
                      {
                        required: true,
                        error: 'Bitte Datum eingeben',
                      },
                      {
                        date: true,
                        error: 'Bitte Datum im Format DD.MM.YYYY eingeben',
                      },
                    ]}
                    onChange={(value) => {
                      this.dataContainer.set(
                        'Entry',
                        'DateOfResub',
                      )(moment(value).format('YYYY-MM-DD'));
                    }}
                    defaultValue={this.dataContainer.get(
                      'Entry',
                      'DateOfResub',
                    )}
                    label="Datum der Wiedervorlage"
                    name="DateOfResub"
                  />
                  <Input
                    type="textarea"
                    validator={[]}
                    onChange={(value) => {
                      this.dataContainer.set('Entry', 'Reason')(value);
                    }}
                    defaultValue={this.dataContainer.get('Entry', 'Reason')}
                    label="Grund"
                    name="Reason"
                  />
                </ContentBox>
              </Form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ResubmissionDetail);
