import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AvailabilityChecker from '../../../components/AvailabilityChecker';
import Form from '../../../components/Form';
import ContentBox from '../../../components/FormArea/ContentBox';
import Dropdown from '../../../components/Inputs/Dropdown';
import Input from '../../../components/Inputs/Input';
import Switch from '../../../components/Inputs/Switch';
import OfficeHours from '../../../components/OfficeHours';
import Treatments from '../../../components/Treatments';
import DataContainer from '../../../logic/dataContainer';
import getConstants from '../../../logic/constants';

const newDataContainer = () => {
  const dataContainer = new DataContainer();
  dataContainer.add('Specialist', {
    Title: '',
    TitleComment: '',
    Firstname: '',
    Lastname: '',
    Praxisname: '',
    JobTitle: '',
    Comment: '',
    PhoneNumber: '',
    Mail: '',
    Website: '',
    InsuranceApproval: '',
    TherapyTimes: '000000000000000000',
    AvailabilityTimes: '',
    OfficeHours: '',
    Treatments: [],
  });
  dataContainer.add('SpecialistOffice', {
    ZIP: '',
    City: '',
    Street: '',
    BarrierFreeAccess: false,
    InfoToReachability: '',
    AdditionalComment: '',
    ParkingSpaces: false,
    LPTWalkingDistance: false,
  });
  return dataContainer;
};
const convertBooleanToString = (booleanString) => {
  return booleanString === true ? 'true' : 'false';
};

class SpecialistForm extends Component {
  dataContainer;
  constructor(props) {
    super(props);

    this.userdata = JSON.parse(localStorage.getItem('userdata'));

    this.state = {
      Salutations: [],
      Titles: [],
      JobTitles: [],
      Disorders: [],
      PreferredContactsCare: [],
      PreferredContactsClient: [],
      InsuranceApprovals: [],
      ReimbursementProcedures: [],
      SettlementWithIncreasedRateOfIncreases: [],
      Users: [],
      isAbsent: false,
      showConsent: false,
    };
    this.dataContainer = props.dataContainer
      ? props.dataContainer
      : newDataContainer();
    this.currentDate = new Date().toJSON().slice(0, 10);
  }
  componentDidMount() {
    this.initConstants();
  }

  async getConstantsPreferredContactCare() {
    return [
      {
        value: 'emailCARE',
        label: 'E-Mail-Adresse für Kontakt mit CARE',
        kkz: '',
      },
      {
        value: 'phoneNumber1',
        label: 'Telefonnummer für Kontakt mit CARE',
        kkz: '',
      },
      {
        value: 'emailCareOrPhoneNumber1',
        label: 'E-Mail-Adresse/Telefonnummer für Kontakt mit CARE',
        kkz: '',
      },
      {
        value: '',
        label: 'Keine Auswahl',
        kkz: '',
      },
    ];
  }

  async getConstantsPreferredContactClient() {
    return [
      {
        value: 'emailClient',
        label: 'E-Mail-Adresse für Kontakt mit KlientInnen',
        kkz: '',
      },
      {
        value: 'phoneNumber2',
        label: 'Telefonnummer für Kontakt mit KlientInnen',
        kkz: '',
      },
      {
        value: 'emailClientAndPhoneNumber2',
        label: 'E-Mail-Adresse/Telefonnummer für Kontakt mit KlientInnen',
        kkz: '',
      },
      {
        value: '',
        label: 'Keine Auswahl',
        kkz: '',
      },
    ];
  }

  async getReimbursementProcedures() {
    return [
      {
        value: 'yes',
        label: 'Ja',
      },
      {
        value: 'no',
        label: 'Nein',
      },
    ];
  }

  async getOption() {
    return [
      {
        value: 'yes',
        label: 'Ja',
      },
      {
        value: 'no',
        label: 'Nein',
      },
    ];
  }

  async initConstants() {
    this.setState({
      Salutations: await getConstants('Salutation'),
      Titles: await getConstants('Title'),
      JobTitles: await getConstants('JobTitle'),
      Disorders: await getConstants('Disorder'),
      PreferredContactsCare: await this.getConstantsPreferredContactCare(),
      PreferredContactsClient: await this.getConstantsPreferredContactClient(),
      InsuranceApprovals: await getConstants('CashRegisterApproval'),
      ReimbursementProcedures: await this.getReimbursementProcedures(),
      SettlementWithIncreasedRateOfIncreases: await this.getOption(),
      Users: await getConstants('CarePsych'),
    });
  }

  validate = async () => {
    return this.form.validate();
  };

  checkAbsent = () => {
    const from = this.dataContainer.get('Specialist', 'AbsentFrom');
    const until = this.dataContainer.get('Specialist', 'AbsentUntil');
    const fromMoment = moment(from, 'DD.MM.YYYY');
    fromMoment.subtract(1, 'day');
    const untilMoment = moment(until, 'DD.MM.YYYY');
    untilMoment.add(1, 'day');
    const absent = moment().isBetween(fromMoment, untilMoment, 'day');
    this.setState({ isAbsent: absent });
  };

  render() {
    return (
      <Form
        ref={(node) => {
          return (this.form = node);
        }}
      >
        <br />
        <ContentBox title="Stammdaten">
          <Input
            type="text"
            onChange={(value) => {
              this.dataContainer.set('Specialist', 'Praxisname')(value);
            }}
            defaultValue={
              this.dataContainer.get('Specialist', 'Praxisname')
                ? this.dataContainer.get('Specialist', 'Praxisname')
                : 'Psychotherapiepraxis'
            }
            label="Name der Einrichtung:"
            isDisabled={this.props.isDisabled}
            name="Praxisname"
          />
          <div className="Input-wrapper spaceer-dummy"></div>
          <Input
            type="text"
            validator={[
              {
                required: true,
                error: 'Pflichtfeld ist nicht ausgefüllt.',
              },
            ]}
            onChange={(value) => {
              this.dataContainer.set('Specialist', 'Firstname')(value);
            }}
            defaultValue={this.dataContainer.get('Specialist', 'Firstname')}
            label="Vorname:"
            isDisabled={this.props.isDisabled}
            name="Firstname"
          />
          <Input
            type="text"
            validator={[
              {
                required: true,
                error: 'Pflichtfeld ist nicht ausgefüllt.',
              },
            ]}
            onChange={(value) => {
              this.dataContainer.set('Specialist', 'Lastname')(value);
            }}
            defaultValue={this.dataContainer.get('Specialist', 'Lastname')}
            label="Nachname:"
            isDisabled={this.props.isDisabled}
            name="Lastname"
          />
          <Dropdown
            label="Titel:"
            getSelected={(value) => {
              this.dataContainer.set('Specialist', 'Title')(value);
            }}
            defaultValue={this.dataContainer.get('Specialist', 'Title')}
            options={this.state.Titles}
            validator={[]}
            isDisabled={this.props.isDisabled}
            name="Title"
          />
          <Input
            type="text"
            onChange={(value) => {
              this.dataContainer.set('Specialist', 'TitleComment')(value);
            }}
            defaultValue={this.dataContainer.get('Specialist', 'TitleComment')}
            label="Bemerkungen zum Titel"
            info="Falls die Titelauswahl ihre genaue Titelbezeichnung nicht abbildet, können Sie hier die Angabe ergänzen."
            isDisabled={this.props.isDisabled}
            name="TitleComment"
          />
          <Dropdown
            label="Berufsbezeichnung:"
            getSelected={(value) => {
              this.dataContainer.set('Specialist', 'JobTitle')(value);
            }}
            defaultValue={this.dataContainer.get('Specialist', 'JobTitle')}
            options={this.state.JobTitles}
            validator={[]}
            isDisabled={this.props.isDisabled}
            name="JobTitle"
          />
          <div className="Input-wrapper spaceer-dummy"></div>
          <Input
            type="textarea"
            fullWidth
            onChange={(value) => {
              this.dataContainer.set('Specialist', 'Comment')(value);
            }}
            defaultValue={this.dataContainer.get('Specialist', 'Comment')}
            label="Bemerkungen:"
            isDisabled={this.props.isDisabled}
            name="Comment"
          />
          <Input
            type="text"
            onChange={(value) => {
              this.dataContainer.set('Specialist', 'PhoneNumber')(value);
            }}
            defaultValue={this.dataContainer.get('Specialist', 'PhoneNumber')}
            label="Telefonnummer für Kontakt mit CARE:"
            info="Über diese Telefonnummer nimmt das CARE-Team Kontakt auf."
            isDisabled={this.props.isDisabled}
            name="PhoneNumber"
          />
          <Input
            type="text"
            onChange={(value) => {
              this.dataContainer.set('Specialist', 'Mail')(value);
            }}
            defaultValue={this.dataContainer.get('Specialist', 'Mail')}
            label="E-Mail-Adresse für Kontakt mit CARE:"
            info="Über diese E-Mail-Adresse nimmt das CARE-Team Kontakt auf."
            isDisabled={this.props.isDisabled}
            name="Mail"
            mailTo="E-Mail senden"
          />
          <Input
            label="Internetauftritt:"
            type="text"
            onChange={(value) => {
              this.dataContainer.set('Specialist', 'Website')(value);
            }}
            defaultValue={this.dataContainer.get('Specialist', 'Website')}
            isDisabled={this.props.isDisabled}
            name="Website"
            linkTo="Klicke, um die Internetadresse in einem neuen Fenster zu öffnen."
          />
        </ContentBox>
        <br />
        <ContentBox title="Fachliche Angaben">
          <Treatments
            isDisabled={this.props.isDisabled}
            name="Treatments"
            label="Behandlungsschwerpunkte in der praktischen Arbeit:"
            info="Bitte wählen Sie die auf Sie zutreffenden Behandlungsschwerpunkte aus. Mehrfachauswahl ist möglich."
            values={this.dataContainer.get('Specialist', 'Treatments')}
            onChange={(values) => {
              this.dataContainer.set('Specialist', 'Treatments')(values);
            }}
          />
          <Dropdown
            label="Kassenzulassung:"
            getSelected={(value) => {
              this.dataContainer.set('Specialist', 'InsuranceApproval')(value);
            }}
            defaultValue={this.dataContainer.get(
              'Specialist',
              'InsuranceApproval',
            )}
            options={this.state.InsuranceApprovals}
            validator={[]}
            isDisabled={this.props.isDisabled}
            name="InsuranceApproval"
          />
        </ContentBox>
        <br />
        <ContentBox title="Generelle Arbeits- und Erreichbarkeitszeiten">
          <AvailabilityChecker
            isDisabled={this.props.isDisabled}
            name="TherapyTimes"
            label="Übersicht der angebotenen Therapiezeiten:"
            info="Bitte markieren Sie die Zeiträume, in denen Sie regelmäßig Behandlungen anbieten.   "
            availableSlots={this.dataContainer.get(
              'Specialist',
              'TherapyTimes',
            )}
            onChange={(value) => {
              this.dataContainer.set('Specialist', 'TherapyTimes')(value.slots);
            }}
          />
          <OfficeHours
            isDisabled={this.props.isDisabled}
            name="OfficeHours"
            label="Telefonische Sprechzeiten:"
            info="Hier können die kassenärztlichen Sprechstunden eingetragen werden, die wir an KlientInnen weitergeben. "
            officeHours={this.dataContainer.get('Specialist', 'OfficeHours')}
            onChange={(value) => {
              this.dataContainer.set('Specialist', 'OfficeHours')(value);
            }}
          />
          <Input
            type="textarea"
            onChange={(value) => {
              this.dataContainer.set('Specialist', 'AvailabilityTimes')(value);
            }}
            defaultValue={this.dataContainer.get(
              'Specialist',
              'AvailabilityTimes',
            )}
            label="Anmerkungen zu Arbeits- und Erreichbarkeitszeiten:"
            isDisabled={this.props.isDisabled}
            name="AvailabilityTimes"
            fullWidth
          />
        </ContentBox>
        <br />
        <ContentBox title="Angaben zum Praxisstandort">
          <Input
            type="text"
            onChange={(value) => {
              this.dataContainer.set('SpecialistOffice', 'ZIP')(value);
            }}
            defaultValue={this.dataContainer.get('SpecialistOffice', 'ZIP')}
            label="PLZ:"
            isDisabled={this.props.isDisabled}
            name="ZIP"
          />
          <Input
            type="text"
            onChange={(value) => {
              this.dataContainer.set('SpecialistOffice', 'City')(value);
            }}
            defaultValue={this.dataContainer.get('SpecialistOffice', 'City')}
            label="Ort:"
            isDisabled={this.props.isDisabled}
            name="City"
          />
          <Input
            type="text"
            onChange={(value) => {
              this.dataContainer.set('SpecialistOffice', 'Street')(value);
            }}
            defaultValue={this.dataContainer.get('SpecialistOffice', 'Street')}
            label="Straße, Hausnummer:"
            isDisabled={this.props.isDisabled}
            name="Street"
          />
          <Switch
            onChange={(value) => {
              this.dataContainer.set(
                'SpecialistOffice',
                'BarrierFreeAccess',
              )(convertBooleanToString(value));
            }}
            defaultChecked={this.dataContainer.get(
              'SpecialistOffice',
              'BarrierFreeAccess',
            )}
            labelActive="Ja"
            labelInactive="Nein"
            label="Barrierefreier Zugang zur Praxis:"
            isDisabled={this.props.isDisabled}
            name="SpecialistOfficeBarrierFreeAccess"
          />
          <Input
            type="textarea"
            onChange={(value) => {
              this.dataContainer.set(
                'SpecialistOffice',
                'InfoToReachability',
              )(value);
            }}
            defaultValue={this.dataContainer.get(
              'SpecialistOffice',
              'InfoToReachability',
            )}
            label="Besonderheiten zur örtlichen Erreichbarkeit:"
            info="Hier können Sie Hinweise zum Zugang Ihrer Praxis eintragen, die wir an KlientInnen weitergeben. Bsp.: Zugang über den Hof"
            isDisabled={this.props.isDisabled}
            name="InfoToReachability"
          />
          <div className="Input-wrapper spaceer-dummy"></div>
          <Input
            type="textarea"
            onChange={(value) => {
              this.dataContainer.set(
                'SpecialistOffice',
                'AdditionalComment',
              )(value);
            }}
            defaultValue={this.dataContainer.get(
              'SpecialistOffice',
              'AdditionalComment',
            )}
            label="Ergänzende Angaben zu telefonischer Erreichbarkeit:"
            info="Hier können Sie abweichende Angaben zu den telefonischen Sprechzeiten und der Terminvergabe am zweiten Praxisstandort ergänzen."
            isDisabled={this.props.isDisabled}
            name="AdditionalComment"
            fullWidth
          />
          <Switch
            onChange={(value) => {
              this.dataContainer.set(
                'SpecialistOffice',
                'ParkingSpaces',
              )(convertBooleanToString(value));
            }}
            defaultChecked={this.dataContainer.get(
              'SpecialistOffice',
              'ParkingSpaces',
            )}
            labelActive="Ja"
            labelInactive="Nein"
            label="Parkplätze in der Nähe der Praxis vorhanden:"
            isDisabled={this.props.isDisabled}
            name="SpecialistOfficeParkingSpaces"
          />
          <Switch
            onChange={(value) => {
              this.dataContainer.set(
                'SpecialistOffice',
                'LPTWalkingDistance',
              )(convertBooleanToString(value));
            }}
            defaultChecked={this.dataContainer.get(
              'SpecialistOffice',
              'LPTWalkingDistance',
            )}
            labelActive="Ja"
            labelInactive="Nein"
            label="Öffentliche Verkehrsmittel fußläufig (ca. 5 min) in der Nähe der Praxis erreichbar:"
            isDisabled={this.props.isDisabled}
            name="SpecialistOfficeLPTWalkingDistance"
          />
        </ContentBox>
        <br />
      </Form>
    );
  }
}

SpecialistForm.propTypes = {
  dataContainer: PropTypes.shape(),
  isDisabled: PropTypes.bool,
};

SpecialistForm.defaultProps = {
  dataContainer: null,
  isDisabled: false,
};

export default SpecialistForm;
