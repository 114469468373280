import React, { Component } from 'react';
import ReactTable from 'react-table';
import { faArrowLeft, faRepeat } from '@fortawesome/pro-regular-svg-icons';
import Loader from '../../../../../components/Loader';
import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import HeaderbarButton from '../../../../../components/HeaderbarButton';
import api from '../../../../../logic/api';

class HistoryOverview extends Component {
  constructor(props) {
    super(props);
    this.clientGuid = props.match.params.clientGuid;
    this.parentRoute = this.props.match.url.replace(
      new RegExp('/history/.*'),
      '',
    );

    this.state = {
      loading: true,
      data: [],
    };
  }

  loadData = () => {
    this.setState({
      data: [],
      loading: true,
    });
    api('get', settings.clientmanagement.history.getList, {
      guid: this.clientGuid,
    })
      .then((res) => {
        const filteredClientData = new DataContainer(res);
        this.setState({
          data: filteredClientData.getMany('Entry'),
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  };

  componentDidMount() {
    this.loadData();
  }

  render() {
    if (this.state.loading) {
      return (
        <div>
          <Loader text="Historien werden geladen..." />
        </div>
      );
    }

    let data = [];
    if (this.state.data.length) {
      data = this.state.data;
    }

    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            <Link to="/clientmanagement">Klientenverwaltung</Link> -{' '}
            <Link
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              Klient
            </Link>{' '}
            - <b>Historie</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton icon={faRepeat} onClick={this.loadData}>
              Liste aktualisieren
            </HeaderbarButton>
          </div>
        </div>

        <div className="Split-Panes-Menu">
          <div className="Split-Pane-Menu">
            <div className="Split-Pane-Menu-Nav">
              <NavLink className="FormArea-Body-Tab" to="/">
                <FontAwesomeIcon icon={faArrowLeft} /> Zurück zur Übersicht
              </NavLink>
              <NavLink
                to={`${this.parentRoute}/${this.clientGuid}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Grunddaten
              </NavLink>
              <NavLink
                to={`${this.parentRoute}/consultation/${this.clientGuid}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Dokumentation
              </NavLink>
              <NavLink
                to={`${this.parentRoute}/caseStatistics/new/${this.clientGuid}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Statistik
              </NavLink>
              <NavLink
                to={`${this.parentRoute}/appointments/${this.clientGuid}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Terminverwaltung
              </NavLink>
              <NavLink
                to={`${this.parentRoute}/resubmission/${this.clientGuid}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Wiedervorlage
              </NavLink>
              <NavLink
                to={`${this.parentRoute}/history/${this.clientGuid}`}
                className="FormArea-Body-Tab"
                activeClassName="is-Active"
              >
                Historie
              </NavLink>
            </div>
          </div>
          <div className="Split-Pane-Main">
            <div className="Page-Content">
              <ReactTable
                defaultSorted={[
                  {
                    id: 'DateOfEdit',
                  },
                ]}
                data={data}
                pageSize={data.length}
                noDataText="Es wurden keine Historien gefunden."
                columns={[
                  {
                    accessor: 'DateOfEdit',
                    Header: 'Datum / Uhrzeit',
                    sortMethod: (a, b) => {
                      return moment(a, 'DD.MM.YYYY hh:mm:ss').isBefore(
                        moment(b, 'DD.MM.YYYY hh:mm:ss'),
                      )
                        ? -1
                        : 1;
                    },
                    minWidth: 250,
                    maxWidth: 250,
                    Cell: ({ original }) => {
                      return (
                        <>
                          {moment(
                            original.DateOfEdit,
                            'DD.MM.YYYY hh:mm:ss',
                          ).format('DD.MM.YYYY / HH:mm')}
                        </>
                      );
                    },
                  },
                  {
                    accessor: 'Action',
                    Header: 'Aktion',
                    Cell: ({ original }) => {
                      return <>{original.Action}</>;
                    },
                  },
                  {
                    accessor: 'UserName',
                    Header: 'Mitarbeiter',
                    Cell: ({ original }) => {
                      return <>{original.UserName}</>;
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HistoryOverview;
