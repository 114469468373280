import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { faEdit, faPlus, faRepeat } from '@fortawesome/pro-regular-svg-icons';
import history from '../../../history';
import settings from '../../../../settings';
import Loader from '../../../components/Loader';
import Searchbar from '../../../components/Searchbar';
import Button from '../../../components/Button';
import DataContainer from '../../../logic/dataContainer';
import getConstants from '../../../logic/constants';
import swal from '@sweetalert/with-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import UserContext from '../../../hooks/UserContext';
import api from '../../../logic/api';
import HeaderbarButton from '../../../components/HeaderbarButton';

class ClientsOverview extends Component {
  constructor(props) {
    super(props);

    let searchString = '';
    if (props.match.params.search) {
      searchString = props.match.params.search;
    }

    this.state = {
      loading: true,
      loadingAppointments: false,
      data: [],
      appointments: [],
      searchString,
      CarePsych: [],
    };
    this.initConstants();
  }

  static contextType = UserContext;

  async initConstants() {
    this.setState({
      CarePsych: await getConstants('CarePsych'),
    });
  }

  loadClients = () => {
    this.setState({
      loading: true,
      data: [],
    });
    const dataColumns = {
      Guid: '',
      LastName: '',
      FirstName: '',
      AddrCity: '',
      UpdateDate: '',
      UpdateUserName: '',
      IsDone: '',
      Question: '',
      CreateDate: '',
      CaseStatus: '',
      Answer: '',
    };
    api(
      'get',
      settings.clientmanagement.client.getFilteredClientList,
      {
        columns: JSON.stringify(dataColumns),
      },
      false,
    )
      .then((res) => {
        const filteredClientDataDC = new DataContainer(res);
        const filteredClientData =
          filteredClientDataDC.getMany('FilteredClient');
        if (filteredClientData.length > 0) {
          this.setState({
            data: filteredClientData,
            loading: false,
          });
          this.loadAppointments();
        }
      })
      .catch(() => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  };

  componentDidMount() {
    this.loadClients();
  }

  loadAppointments() {
    this.setState({
      loadingAppointments: true,
    });
    const dataColumns = {
      Typ: 'Appointment',
      ClientGuid: this.clientGuid,
    };
    api('get', settings.clientmanagement.dataContainer.getList, {
      parameter: JSON.stringify(dataColumns),
    })
      .then((res) => {
        const appointmentsDC = new DataContainer(res);
        this.setState({
          appointments: appointmentsDC.getMany('Entry'),
          loadingAppointments: false,
        });
      })
      .catch(() => {
        this.setState({
          loadingAppointments: false,
        });
      });
  }

  _search = (value) => {
    const val = value.toLowerCase();

    history.replace(
      val ? `/clientmanagement/search/${val}` : '/clientmanagement',
    );

    this.setState({
      searchString: val,
    });
  };

  _openClient = (client) => {
    swal({
      title: 'Sicherheitsabfrage',
      text: `Bitte wählen...`,
      buttons: {
        back: 'abbrechen',
        without: {
          text: 'ohne Sicherheitsfrage öffnen',
        },
        correct: 'Telefonische Beratung, Antwort prüfen',
      },
    }).then((value) => {
      switch (value) {
        case 'without':
          return this.props.history.push(
            `/clientmanagement/detail/${client.Guid}`,
          );
        case 'correct':
          swal({
            title: `${client.Question}`,
            text: `Antwort: ${client.Answer}`,
            buttons: {
              back: 'Antwort falsch',
              correct: 'Antwort richtig',
            },
          }).then((value) => {
            switch (value) {
              case 'correct':
                swal(
                  'Telefonische Beratung. Zugriff wird in Historie gespeichert.',
                  { icon: 'success' },
                );
                return this.props.history.push(
                  `/clientmanagement/detail/${client.Guid}/log`,
                );
              default:
                break;
            }
          });
          break;
        default:
          break;
      }
    });
  };

  _deleteClient = (client) => {
    swal({
      title: 'Löschen?',
      buttons: {
        cancle: 'abbrechen',
        delete: 'löschen',
      },
    }).then((value) => {
      if (value !== 'delete') {
        return;
      }
      api('POST', settings.clientmanagement.dataContainer.remove, {
        guid: client.Guid,
        action: 'Klient gelöscht',
        clientGuid: client.Guid,
      })
        .then(() => {
          swal({
            title: 'Gelöscht!',
            text: 'Erfolgreich gelöscht.',
            icon: 'success',
          });
          this.props.history.goBack();
        })
        .catch(() => {
          swal({
            title: 'Löschen nicht möglich!',
            text: 'Es ist ein Fehler beim löschen aufgetreten',
            icon: 'error',
          });
        });
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader text="Klienten werden geladen..." />;
    }

    let { data } = this.state;
    if (data.length) {
      data = data.filter((rowData) => {
        return [
          rowData.LastName,
          rowData.FirstName,
          rowData.AddrCity,
          rowData.UpdateUserName,
          rowData.LastContact,
          rowData.CreateDate,
          rowData.CaseStatus,
          rowData.HasCaseStatistic,
        ]
          .map((e) => {
            return e ? e.toLowerCase() : e;
          })
          .some((v) => {
            return v ? v.indexOf(this.state.searchString) >= 0 : false;
          });
      });
    }

    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            <b>Klientenverwaltung</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton icon={faRepeat} onClick={this.loadClients}>
              Liste aktualisieren
            </HeaderbarButton>
          </div>
        </div>
        <div className="Page-Content">
          <div className="Buttonbar">
            <Button
              icon={faPlus}
              onClick={() => {
                this.props.history.push(`/clientmanagement/new`);
              }}
              replace
            >
              Neuer Klient
            </Button>
          </div>
          <div className="Toolbar">
            <Searchbar
              placeholder="Volltextsuche"
              defaultValue={this.state.searchString}
              onChange={this._search}
            />
          </div>
          <ReactTable
            getTrProps={(_, val) => {
              return {
                onClick: (ev) => {
                  if (ev.target.className === 'delete') {
                    return this._deleteClient(val?.original);
                  }
                  return this._openClient(val?.original);
                },
              };
            }}
            defaultSorted={[
              {
                id: 'LastName',
                desc: false,
              },
            ]}
            pageSize={data.length}
            data={data}
            noDataText="Es wurden keine Klienten gefunden."
            columns={[
              {
                accessor: 'LastName',
                Header: 'Name',
                Cell: ({ original }) => {
                  return <>{original.LastName}</>;
                },
              },
              {
                accessor: 'FirstName',
                Header: 'Vorname',
                Cell: ({ original }) => {
                  return <>{original.FirstName}</>;
                },
              },
              {
                accessor: 'AddrCity',
                Header: 'Wohnort',
                Cell: ({ original }) => {
                  return <>{original.AddrCity}</>;
                },
              },
              {
                accessor: 'CarePsych',
                Header: 'Beraten durch',
                Cell: ({ original }) => {
                  return <>{this.getCarePsychByAppointment(original.Guid)}</>;
                },
              },
              {
                accessor: 'CreateDate',
                Header: 'Angelegt am',
                sortMethod: (a, b) => {
                  return moment(a, 'DD.MM.YYYY hh:mm:ss').isBefore(
                    moment(b, 'DD.MM.YYYY hh:mm:ss'),
                  )
                    ? -1
                    : 1;
                },
                Cell: ({ original }) => {
                  return (
                    <>
                      {moment(
                        original.CreateDate,
                        'DD.MM.YYYY hh:mm:ss',
                      ).format('DD.MM.YYYY')}
                    </>
                  );
                },
              },
              {
                accessor: 'UpdateDate',
                Header: 'Letzter Kontakt',
                sortMethod: (a, b) => {
                  return moment(a, 'DD.MM.YYYY hh:mm:ss').isBefore(
                    moment(b, 'DD.MM.YYYY hh:mm:ss'),
                  )
                    ? -1
                    : 1;
                },
                Cell: ({ original }) => {
                  return (
                    <>
                      {moment(
                        original.UpdateDate,
                        'DD.MM.YYYY hh:mm:ss',
                      ).format('DD.MM.YYYY')}
                    </>
                  );
                },
              },
              {
                accessor: 'CaseStatus',
                Header: 'Fallstatus',
                Cell: ({ original }) => {
                  return <>{original.CaseStatus ? original.CaseStatus : ''}</>;
                },
              },
              {
                accessor: 'HasCaseStatistic',
                Header: 'Hat Statistikdaten',
                Cell: ({ original }) => {
                  return (
                    <>
                      {original.HasCaseStatistic
                        ? original.HasCaseStatistic
                        : ''}
                    </>
                  );
                },
              },
              {
                accessor: 'Link',
                Header: 'Zur Detailansicht',
                Cell: () => {
                  return (
                    <>
                      <u>Hier klicken</u>&nbsp;
                      <FontAwesomeIcon icon={faEdit} />
                    </>
                  );
                },
              },
            ]}
          />
        </div>
      </>
    );
  }

  getCarePsychByAppointment(Guid) {
    let carePsychName = '';
    const appointment = this.state.appointments.find((a) => {
      return a.ClientGuid === Guid;
    });
    if (appointment) {
      carePsychName = this.state.CarePsych.find((elem) => {
        return elem.value === appointment.CarePsych;
      })?.label;
    }
    return carePsychName;
  }
}

export default withRouter(ClientsOverview);
