import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import reqwest from 'reqwest';

import {
  faBars,
  faHome,
  faSignOut,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import settings from '../../settings';
import logo from '../../images/logo.png';
import navJson from './Navigation.json';
import UserContext from '../hooks/UserContext';

class Navigation extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      // close menu on navigation
      this.setState({
        openMenu: false,
      });
    }
  }

  logout = () => {
    reqwest({
      method: 'get',
      url: settings.general.logout,
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then(() => {
      localStorage.clear();
      this.context.setUser(null);
      window.location.reload();
    });
  };

  toggleMenu = () => {
    this.setState((prevState) => {
      return {
        openMenu: !prevState.openMenu,
      };
    });
  };

  closeMenu = () => {
    this.setState({
      openMenu: false,
    });
  };

  render() {
    let menuJson = navJson.menuManagement;
    if (this.context.isManagementSpecial) {
      menuJson = navJson.menuManagementSpecial;
    } else if (this.context.isAdmin) {
      menuJson = navJson.menuAdmin;
    }

    return (
      <div className="Navigation">
        <div
          className={`Navigation-Left ${
            this.state.openMenu ? 'is-Active' : ''
          }`}
        >
          <div className="Navigation-Left-Top">
            <button
              className={`Navigation-FirstLevelMenu-Toggle ${
                this.state.openMenu ? 'is-Active' : ''
              }`}
              onClick={this.toggleMenu}
            >
              {this.state.openMenu ? (
                <FontAwesomeIcon icon={faTimes} />
              ) : (
                <FontAwesomeIcon icon={faBars} />
              )}
            </button>
          </div>
          <div className="Navigation-Left-Top-2">
            <Link to="/" className="Navigation-Home">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </div>
          <nav className="Navigation-Sidenav">
            <div
              className={`Navigation-SecondLevelmenu ${
                this.state.openMenu ? 'is-Active' : ''
              }`}
            >
              {menuJson.map((menu) => {
                return (
                  <Link
                    key={menu.title}
                    className={`Navigation-SecondLevelmenu-Link ${
                      menu.url === this.props.location.pathname
                        ? 'is-Active'
                        : ''
                    }`}
                    to={menu.url}
                  >
                    {menu.title}
                  </Link>
                );
              })}
            </div>
          </nav>
        </div>
        <div className="Navigation-Top">
          <div className="Navigation-Top-1">
            {process.env.NODE_ENV === 'development' && (
              <div className="DevPatch">
                <div className="DevPatch-Inner">Dev</div>
              </div>
            )}
            <div className="Navigation-Top-Logo">
              <Link to="/">
                <img src={logo} alt="careDB" />
              </Link>
            </div>{' '}
            <div>
              {this.context.userdata?.firstname}{' '}
              {this.context.userdata?.lastname}
            </div>
            <div className="Navigation-Top-Logout">
              <button className="Logout-button" onClick={this.logout}>
                Logout <FontAwesomeIcon icon={faSignOut} />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Navigation);
